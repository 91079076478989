import React, { useState, useMemo, useCallback, useEffect } from "react"
import styled from "styled-components"
import { Button, Typography } from "antd"
import { useSelector, useDispatch } from "react-redux"
import ReactFlow, {
  Background,
  MiniMap,
  ReactFlowProvider,
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
} from "reactflow"
import { isEqual } from "lodash"

import { Modal } from "@dbai/ui-staples"
import { alphanumid } from "@dbai/tool-box"

import { actions } from "reducers/notebookReducer"
import { selectNodes, selectEdges } from "selectors"
import EmptyNode from "components/pages/Workflows/Edit/Graph/CustomNodes/EmptyNode"
import ScriptNode from "components/pages/Workflows/Edit/Graph/CustomNodes/ScriptNode"
import ComponentNode from "components/pages/Workflows/Edit/Graph/CustomNodes/ComponentNode"
import DBStandardEdge from "components/pages/Workflows/Edit/Graph/CustomNodes/DBStandardEdge"
import "reactflow/dist/style.css"

const GraphViewContainer = styled.div`
  width: 100%;
  height: 100%;
`

const MiniMapStyled = styled(MiniMap)`
  background-color: ${props => props.theme.minimapMaskBg};

  .react-flow__minimap-mask {
    fill: ${props => props.theme.minimapMaskFill};
  }

  .react-flow__minimap-node {
    fill: ${props => props.theme.minimapMaskNode};
    stroke: none;
  }
`
const ModalButtons = styled(Button.Group)`
  width: 100%;

  .ant-btn {
    width: 100%;
  }
`

const nodeTypes = {
  empty: props => <EmptyNode {...props} />,
  script: props => <ScriptNode {...props} />,
  component: props => <ComponentNode {...props} />,
}

const edgeTypes = {
  default: props => <DBStandardEdge {...props} />,
}

const deleteKeyCodes = ["Backspace", "Delete"]

const NodeDeletionConfirmation = props => {
  const { selectedNodes = [], closeModal } = props

  const dispatch = useDispatch()

  const deleteSelected = useCallback(() => {
    dispatch(actions.deleteNodes({ nodes: selectedNodes }))
  }, [dispatch, selectedNodes])

  const modalTitle = useMemo(
    () => selectedNodes[0]?.data?.label,
    [selectedNodes]
  )

  const handleClose = useCallback(
    e => {
      e.stopPropagation()
      e.preventDefault()
      closeModal()
    },
    [closeModal]
  )

  const handleDeletion = useCallback(
    e => {
      e.stopPropagation()
      e.preventDefault()

      deleteSelected()
      closeModal()
    },
    [closeModal, deleteSelected]
  )

  const message = useMemo(() => {
    if (selectedNodes.length === 1) {
      return `Are you sure you want to delete ${modalTitle ?? "Node"}? All code in this node will be deleted.`
    }
    return (
      <>
        Are you sure you want to delete the following nodes?
        <ul>
          {selectedNodes.map(node => (
            <li key={node.id}>{node.data.label}</li>
          ))}
        </ul>
        All code in these nodes will be deleted.
      </>
    )
  }, [modalTitle, selectedNodes])

  return (
    <div>
      <Typography.Title level={4} style={{ color: "#000" }}>
        {message}
      </Typography.Title>

      <ModalButtons>
        <Button type="primary" danger onClick={handleDeletion}>
          DELETE
        </Button>
        <Button type="default" onClick={handleClose}>
          CANCEL
        </Button>
      </ModalButtons>
    </div>
  )
}

const checkNodesChange = (nodes, specNodes) => {
  if (nodes.length !== specNodes.length) return true
  return specNodes.reduce((acc, node, index) => {
    if (acc) return true
    const found = nodes.find(n => n.id === node.id)
    if (!found) return true
    if (found.type !== node.type) return true
    if (!isEqual(found.position, node.position)) return true
    if (!isEqual(found.data, node.data)) return true
    if (!isEqual(found.style, node.style)) return true
    if (!isEqual(found.height, node.height)) return true
    if (!isEqual(found.width, node.width)) return true
    if (!isEqual(found.dropped, node.dropped)) return true
    return false
  }, false)
}

const Flow = () => {
  const specNodes = useSelector(selectNodes)
  const specEdges = useSelector(selectEdges)
  const [deleteNodes, setDeleteNodes] = useState([])
  const [reactFlowInstance, setReactFlowInstance] = useState(null)
  const dispatch = useDispatch()

  const [nodes, setNodes] = useState(specNodes)
  const [edges, setEdges] = useState(specEdges)

  const [sourceNode, setSourceNode] = useState(null)
  const [validConnectionMade, setValidConnectionMade] = useState(false)

  const handleConnect = useCallback(
    connection => {
      if (connection.source && connection.target) {
        const edge = {
          ...connection,
          type: "default",
          id: alphanumid(),
        }
        dispatch(actions.addEdge(edge))
        setEdges(edges => addEdge(edge, edges))
        setValidConnectionMade(true)
      }
    },
    [dispatch]
  )

  const handleConnectStart = useCallback((event, { nodeId }) => {
    setSourceNode(nodeId)
  }, [])

  const handleConnectEnd = useCallback(
    event => {
      if (validConnectionMade) {
        setValidConnectionMade(false)
        return
      }

      const targetIsPane = event.target.classList.contains("react-flow__pane")
      if (targetIsPane) {
        const position = reactFlowInstance.screenToFlowPosition({
          x: event.clientX,
          y: event.clientY,
        })

        const newNode = dispatch(actions.addNode(position))
        setNodes(nodes => [...nodes, newNode])

        if (sourceNode) {
          const newEdge = {
            id: alphanumid(),
            source: sourceNode,
            target: newNode.id,
            type: "default",
          }
          dispatch(actions.addEdge(newEdge))
          setEdges(edges => addEdge(newEdge, edges))
        }
      }
    },
    [dispatch, reactFlowInstance, sourceNode, validConnectionMade]
  )

  const handleShiftClick = useCallback(
    e => {
      if (e.shiftKey) {
        const position = reactFlowInstance.screenToFlowPosition({
          x: e.clientX,
          y: e.clientY,
        })

        const node = dispatch(actions.addNode(position))
        setNodes(nodes => [...nodes, node])
      }
    },
    [dispatch, reactFlowInstance]
  )

  const checkIfEmpty = useCallback(
    nodes => {
      if (nodes[0].type === "empty") {
        dispatch(actions.deleteNodes({ nodes: nodes }))
      } else {
        setDeleteNodes(nodes)
      }
    },
    [dispatch]
  )

  const deleteSelectedEdge = useCallback(
    edges => {
      edges.forEach(edge => {
        if (!edge.selected) return
        dispatch(actions.deleteEdgebyId({ id: edge.id }))
      })
    },
    [dispatch]
  )

  const handleNodeDragStop = useCallback(
    (event, cbNode, nodes) => {
      nodes.forEach(node => {
        const {
          id,
          position,
          data: { runningStatus, ...cleansedData },
        } = node

        dispatch(
          actions.updateNodePosition(position, specNodes, id, cleansedData)
        )
      })
    },
    [dispatch, specNodes]
  )

  const handleEdgesChange = useCallback(changes => {
    setEdges(edges => applyEdgeChanges(changes, edges))
  }, [])

  const handleNodesChange = useCallback(changes => {
    setNodes(nodes => applyNodeChanges(changes, nodes))
  }, [])

  const closeModal = useCallback(() => {
    setNodes(specNodes)
    setEdges(specEdges)
    setDeleteNodes([])
  }, [specNodes, specEdges])

  useEffect(() => {
    setNodes(prevNodes => {
      if (checkNodesChange(prevNodes, specNodes)) {
        return specNodes
      } else {
        return prevNodes
      }
    })
  }, [specNodes])

  useEffect(() => {
    if (edges.length !== specEdges.length) {
      setEdges(specEdges)
    }
  }, [specEdges, edges])

  return (
    <GraphViewContainer>
      <ReactFlow
        fitView="true"
        fitViewOptions={{ padding: 3 }}
        nodes={nodes}
        edges={edges}
        onInit={setReactFlowInstance}
        onClick={handleShiftClick}
        onConnect={handleConnect}
        onConnectStart={handleConnectStart}
        onConnectEnd={handleConnectEnd}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onNodesDelete={checkIfEmpty}
        onNodesChange={handleNodesChange}
        onEdgesChange={handleEdgesChange}
        onEdgesDelete={deleteSelectedEdge}
        deleteKeyCode={deleteKeyCodes}
        onNodeDragStop={handleNodeDragStop}
      >
        <Background />
        <MiniMapStyled />
      </ReactFlow>
      <Modal isOpen={deleteNodes.length} onRequestClose={closeModal}>
        <NodeDeletionConfirmation
          closeModal={closeModal}
          selectedNodes={deleteNodes}
        />
      </Modal>
    </GraphViewContainer>
  )
}

const Graph = () => (
  <ReactFlowProvider>
    <Flow />
  </ReactFlowProvider>
)

export default Graph
