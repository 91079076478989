import React, { useMemo, useState } from "react"
import lodash from "lodash"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import { Button } from "antd"

import {
  DBTable,
  Content,
  SidePane,
  PageSpinner,
  ErrorMessage,
  useCurrentCustomer,
} from "@dbai/ui-staples"

import { stringSorter, dateSorter } from "@dbai/tool-box"

import { GET_WORKFLOW_RUNS } from "queries"
import WorkflowDetails from "./WorkflowDetails"

const dateTimeFor = key => props => {
  const { value } = props
  if (!value || !value[key]) return null
  const dateTimeString = value[key]
  const localDateTime = new Date(dateTimeString).toLocaleString()

  return <>{localDateTime}</>
}

const StartTime = dateTimeFor("startedAt")
const FinishTime = dateTimeFor("finishedAt")

const SelectWorkflow = props => {
  const { row, handleClick } = props

  if (row?.argoWorkflow?.status?.phase === "Running") return null

  return (
    <Button type="primary" size="small" onClick={() => handleClick(row)}>
      DETAILS
    </Button>
  )
}

const WorkflowHistoryTable = props => {
  const { priorRuns } = props
  const [customer] = useCurrentCustomer()
  const [selected, setSelected] = useState(null)

  const columns = [
    {
      title: "Phase",
      dataIndex: "phase",
      key: "phase",
      sorter: stringSorter("phase"),
    },
    {
      title: "Start",
      dataIndex: "startedAt",
      key: "startedAt",
      defaultSortOrder: "ascend",
      sorter: dateSorter("startedAt"),
      render: (text, row) => {
        return <StartTime value={row} />
      },
    },
    {
      title: "Finish",
      dataIndex: "finishedAt",
      key: "finishedAt",
      sorter: dateSorter("finishedAt"),
      render: (text, row) => {
        return <FinishTime value={row} />
      },
    },
    {
      title: "Details",
      dataIndex: "argoworkflow.metadata",
      key: "argoworkflow.metadata",
      render: (text, row) => {
        return <SelectWorkflow row={row} handleClick={setSelected} />
      },
    },
  ]

  const sortedRuns = useMemo(() => {
    return lodash.orderBy([...priorRuns], ["createdAt"], ["desc"]).map(run => ({
      ...run,
      startedAt: run?.argoWorkflow?.status?.startedAt,
      finishedAt: run?.argoWorkflow?.status?.finishedAt,
      phase: run?.argoWorkflow?.status?.phase,
    }))
  }, [priorRuns])

  if (!selected) {
    return (
      <Content>
        <DBTable
          style={{ width: "100%" }}
          dataSource={sortedRuns}
          pagination={{ hideOnSinglePage: true }}
          columns={columns}
        >
        </DBTable>
      </Content>
    )
  }

  if (!customer) return null
  const handleDeselect = () => setSelected(null)

  return (
    <WorkflowDetails
      workflowRun={selected}
      customerId={customer.id}
      onDeselect={handleDeselect}
    />
  )
}

const extractWorkflowRuns = data => data?.customer?.workflow?.workflowRuns

const SidePaneWithoutPadding = styled(SidePane)`
  .sidepane-content {
    padding: 0px;
  }
`

const SidePaneContent = props => {
  const { id, cname } = props
  const { loading, data, error } = useQuery(GET_WORKFLOW_RUNS, {
    variables: { id, cname },
    pollInterval: 5000,
  })

  const workflowRuns = useMemo(() => {
    return extractWorkflowRuns(data)
  }, [data])

  if (loading) return <PageSpinner />
  if (error) return <ErrorMessage error={error} />

  return <WorkflowHistoryTable priorRuns={workflowRuns} />
}

const ContentOrNull = props => {
  const { isVisible, ...rest } = props

  if (!isVisible) return null
  return <SidePaneContent {...rest} />
}

const WorkflowHistory = props => {
  const { panelOpen, closePanel } = props

  return (
    <>
      <SidePaneWithoutPadding
        width="50vw"
        isVisible={panelOpen}
        onCloseClicked={closePanel}
        title="Prior Runs"
        mask
      >
        <ContentOrNull isVisible={panelOpen} {...props} />
      </SidePaneWithoutPadding>
    </>
  )
}

WorkflowHistory.propTypes = {
  id: PropTypes.number.isRequired,
  cname: PropTypes.string.isRequired,
}

export default WorkflowHistory
