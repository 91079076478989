import * as Y from "yjs"
import { curry } from "lodash"

import { actions } from "reducers/notebookReducer"

/*
 * This file contains initialization logic related to Yjs. These functions
 * handle things such as the initial data sync as well as creating observers
 * that update the local redux store when a node is changed.
 *
 * # General things to note.
 *
 * In functions that handle a "Y" event you will often see a comparison
 * between `event.target` and some Y type. This is done to ensure that the
 * given event is mapped to what we expect in the case of "deep" observe
 * functions.
 *
 * Often you will see a check for the existence of `event.transaction.origin`.
 * If the value of `origin` is null, the event was locally triggered and we
 * don't actually want to trigger a local state update over again.
 *
 * Documentation for the various event types can be found here:
 * - Y.MapEvent https://docs.yjs.dev/api/shared-types/y.map#observing-changes-y-mapevent
 * - Y.ArrayEvent https://docs.yjs.dev/api/shared-types/y.array#observing-changes-y-arrayevent
 */

/*
 * CodeMirror and Y store our cell sources as strings. Redux/Jupyter want
 * arrays. This simple helper function fromats from Y/CM to Redux/Jupyter.
 */
const formatCellSource = cell => ({
  ...cell,
  source: cell.source.split(/(?<=\n)/),
})

const nodeToReduxShape = node => {
  const jsonNode = node.toJSON()

  if (!jsonNode.cells) return jsonNode

  return {
    ...node.toJSON(),
    cells: jsonNode.cells.map(formatCellSource),
  }
}

/*
 * Given a Y.Array of cells, this function will handle syncing remote updates
 * for the given cell list with the local Redux store.
 */
const handleCellUpdate = curry((cells, dispatch, yMapEvent) => {
  if (yMapEvent.target instanceof Y.Text) return
  if (!yMapEvent.transaction.origin) return
  const nodeId = cells.parent.get("id")

  dispatch(
    actions.syncCellsForNode({
      nodeId,
      cells: cells.toJSON().map(formatCellSource),
    })
  )
})

/*
 * Given a node this function will handle syncing remote updates to it's first level
 * attributes with our local redux store.
 */
const handleNodeUpdate = curry((dispatch, node, yMapEvent) => {
  if (yMapEvent.target !== node) return
  if (!yMapEvent.transaction.origin) return

  yMapEvent.changes.keys.forEach((change, key) => {
    switch (change.action) {
      case "update":
        if (
          key === "type" &&
          (node.toJSON().type === "script" ||
            node.toJSON().type === "component") &&
          change.oldValue === "empty"
        ) {
          const cells = node.get("cells")
          dispatch(
            actions.syncCellsForNode({
              nodeId: node.toJSON().id,
              cells: cells.toJSON().map(formatCellSource),
            })
          )
        }

        dispatch(actions.syncNodeMetadata(node.toJSON()))
        break
      default:
        return
    }
  })
})

/*
 * Nested under the top level Y.Map "nodes" we have multiple nodes that are
 * Y.Map representations of our individual nodes. The following function will
 * initialize an observer on the given node and handle syncing updates between
 * remote events and our local Redux store. Note that this is a "deep"
 * observer so changes to any nested attribute inside of the given node will
 * trigger this function.
 */
export const observeNode = curry((dispatch, node) => {
  node.observeDeep(events => {
    const cells = node.get("cells")

    if (cells) {
      events.forEach(handleCellUpdate(cells, dispatch))
    }

    events.forEach(handleNodeUpdate(dispatch, node))
  })
})

/*
 * At the top level of our yDoc we have two attributes; nodes and edges. Edges
 * is a Y.Array and this initializes a top level observer on that array. Note
 * that this is not a "deep" observer, so should not see changes to nested
 * attributes here.
 */
export const observeEdges = curry((dispatch, edges) => {
  edges.observe(yArrayEvent => {
    dispatch(actions.syncEdges(edges.toJSON()))
  })
})

/*
 * At the top level of our yDoc we have two attributes; nodes and edges. Nodes
 * is a Y.Map and this initializes a top level observer on that map. Note that
 * this is not a "deep" observer, so we should not see changes on nested
 * attributes here.
 */
export const observeNodeMap = curry((dispatch, nodes) => {
  const addObserver = key => {
    const newYNode = nodes.get(key)
    observeNode(dispatch, newYNode)
    return newYNode
  }

  nodes.observe(yMapEvent => {
    if (!yMapEvent.transaction.origin) {
      yMapEvent.changes.keys.forEach((change, key) => {
        switch (change.action) {
          case "add":
            addObserver(key)
            break
          default:
            return
        }
      })
      return
    }

    yMapEvent.changes.keys.forEach((change, key) => {
      switch (change.action) {
        case "add":
          const newYNode = addObserver(key)
          dispatch(actions.appendNode(nodeToReduxShape(newYNode)))
          break
        case "delete":
          dispatch(actions.removeNodeById({ nodeId: key }))
          break
        default:
          return
      }
    })
    if (!yMapEvent.transaction.origin) return
  })
})

const observeMetadata = curry((dispatch, metadata) => {
  metadata.observe(yMapEvent => {
    if (!yMapEvent.transaction.origin) return

    if (yMapEvent.keysChanged.has("name")) {
      dispatch(
        actions.set({ name: "workflow.name", value: metadata.get("name") })
      )
    }
  })
})
/*
 * When called, creates observers on our yDoc to keep our redux store in sync
 * with remote changes.
 */
export const initObservers = (yDoc, dispatch) => {
  yDoc.transact(() => {
    const nodes = yDoc.getMap("nodes")
    const edges = yDoc.getArray("edges")
    observeNodeMap(dispatch, nodes)
    nodes.forEach(observeNode(dispatch))
    observeEdges(dispatch, edges)
    observeMetadata(dispatch, yDoc.get("metadata"))
  })
}

export const addEntriesToYMap = (map, obj) => {
  Object.entries(obj).forEach(([key, value]) => {
    map.set(key, value)
  })
}

export const createNewYCell = cellData => {
  const { source, ...rest } = cellData
  const newCell = new Y.Map()

  addEntriesToYMap(newCell, { ...rest })

  const text = new Y.Text()
  const sourceString = source.join("")

  text.insert(0, sourceString)
  newCell.set("source", text)

  return newCell
}

/*
 * Creates a cell in the yDoc on a given node (yNode). This "cell" will be a
 * key/value where the key is uuid and the value is a Y.Text created from
 * source.
 */
export const initCell = curry((yNode, cell) => {
  const { uuid } = cell

  if (yNode.get(uuid)) return

  const map = createNewYCell(cell)

  return map
})

/*
 * Given an id, a type, and a set of cells, initialize a Y.Map on the yDoc to
 * represent a node and add it under the base "nodes" Y.Map.
 */
export const initNode = curry(
  (yDoc, dispatch, { id, cells = [], type, ...rest }) => {
    const nodes = yDoc.getMap("nodes")
    const node = new Y.Map()

    addEntriesToYMap(node, { type, id, ...rest })

    if (cells && (type === "script" || type === "component")) {
      const yCells = cells.map(initCell(node))
      node.set("cells", Y.Array.from(yCells))
    }

    nodes.set(id, node)
    return node
  }
)

/*
 * Initializes the spec. Note that our yDoc spec is shaped differently than
 * what is stored in Redux.
 *
 * In Redux, our spec takes the following shape:
 * type Spec = {
 *  // `nodes` is an array of nodes.
 *  nodes: {
 *    id: string
 *    cells: { uuid, source... }[]
 *    ...otherAttributes
 *  }[]
 *  edges: Edge[]
 * }
 *
 * In our yDoc, we have the following shape:
 * type Spec = {
 *  // `nodes` is a map of maps where the keys of the map corespond to nodes
 *  // in our Redux store.
 *  nodes: {
 *    [string]: {
 *      id: string
 *      cells: { uuid, source... }[]
 *      ...otherAttributes
 *    }
 *  },
 *  edges: Edge[]
 * }
 * Please pardon my TypeScript. :p
 *
 * This difference in structure is far easier to work with seeing as we do not
 * actually have to track positional data of nodes in the yDoc.
 */
export const initSpec = ({ yDoc, spec, dispatch }) => {
  if (spec && spec.nodes) {
    yDoc.transact(() => {
      spec.nodes.forEach(initNode(yDoc, dispatch))
      const edges = yDoc.getArray("edges")
      spec.edges.forEach(edge => edges.push([edge]))
    })
  }
}
