const hasCycle = (nodes, edges, newEdge) => {
  const adjList = {}
  const visited = new Set()
  const recursionStack = new Set()

  nodes.forEach(node => {
    adjList[node.id] = []
  })
  edges.forEach(edge => {
    adjList[edge.source].push(edge.target)
  })

  adjList[newEdge.source].push(newEdge.target)

  const dfs = nodeId => {
    if (recursionStack.has(nodeId)) {
      return true
    }
    if (visited.has(nodeId)) {
      return false
    }

    visited.add(nodeId)
    recursionStack.add(nodeId)

    for (const neighbor of adjList[nodeId]) {
      if (dfs(neighbor)) {
        return true
      }
    }

    recursionStack.delete(nodeId)
    return false
  }

  for (const node of nodes) {
    if (!visited.has(node.id)) {
      if (dfs(node.id)) {
        return true
      }
    }
  }

  return false
}

export default hasCycle
