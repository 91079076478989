import { get, set, uniqBy } from "lodash"
import { createSlice } from "@reduxjs/toolkit"

import { createCommonActions } from "@dbai/tool-box"

import createThunks from "./thunks"
import nodeSort from "lib/nodeSorting"
import {
  curry,
  getNodeById,
  getCellById,
  isScriptNode,
  findOutputSource,
  getConnectingEdge,
} from "lib/utils"

const directions = {
  up: -1,
  down: 1,
}

const initialState = {
  isDefaultState: true,
  isLoading: false,
  autosave: {
    paused: false,
  },
  notebook: {
    historyPanelOpen: false,
    autoScroll: true,
    disableMoveCursor: false,
    mode: "command",
    focused: {
      node: null,
      cellIdx: null,
    },
    layout: {
      selected: "both",
      doc: ["code", "output"],
      codeWidth: 60,
      graphWidth: 40,
    },
    cellStatuses: {},
  },
  workflow: { spec: { nodes: [] } },
}

const filterTypenames = obj => {
  const { __typename, ...rest } = obj
  return rest
}

// The type of nodes we want to navigate through.
const navigationTargets = ["script"]

const nodesPath = ["workflow", "spec", "nodes"]

const filterNavigatable = node => {
  const isNavigatableType = Boolean(navigationTargets.includes(node.type))
  return isNavigatableType
}

const getTransitionData = (direction, cells) => {
  if (direction === 1) {
    return {
      transitionCellIndex: 0,
    }
  }

  return {
    transitionCellIndex: Math.max(cells.length - 1, 0),
  }
}

const getMinAboveZero = (val1, val2) => Math.max(0, Math.min(val1, val2))

/*
 * It is possible for a node to be collapsed that was currently focused by
 * this user. In that case if a navigation key is used we want to transition
 * out of the collapsed node to the next navigatable node in the direction
 * that the key would indicate.
 */
const getNextVisibleNode = ({
  currentNodeId,
  nodes,
  filteredNodes,
  direction,
}) => {
  const currentNodeIndex = nodes.findIndex(node => node.id === currentNodeId)

  if (direction === 1) {
    const after = nodes.slice(currentNodeIndex + 1)
    return after.find(node => filteredNodes.includes(node))
  }

  const before = nodes.slice(0, currentNodeIndex)
  return before.reverse().find(node => filteredNodes.includes(node))
}

/*
 * Helps transition between cells taking a direction and the current draft
 * as arguments. If the cell that has focus in the current node is the
 * first or the last cell and the direction of travel would put this beyond
 * that current node, this function handles that scenario by transitioning to
 * the next node or gracefully staying put if there is no node in that
 * direction.
 */
const createNewFocus = (directionKey, draft) => {
  const {
    notebook: { focused },
    workflow: {
      spec: { nodes },
    },
  } = draft
  if (!focused.node) return focused
  const findNode = node => node.id === focused.node
  const filteredNodes = nodes.filter(filterNavigatable)
  const node = nodes.find(findNode)
  const cellCount = node.cells.length
  const direction = directions[directionKey]
  const nextIndex = focused.cellIdx + direction
  const nextNode =
    getNextVisibleNode({
      currentNodeId: node.id,
      nodes,
      filteredNodes,
      direction,
    }) || node

  if (cellCount === nextIndex || nextIndex < 0 || node.collapsed) {
    const maxCellIndex = node.cells.length - 1
    const { transitionCellIndex } = getTransitionData(direction, nextNode.cells)

    const nextCellIndex =
      nextNode === node
        ? Math.min(nextIndex, maxCellIndex)
        : transitionCellIndex
    return {
      node: nextNode.id,
      cellIdx: Math.max(0, nextCellIndex),
    }
  }

  return {
    ...focused,
    cellIdx: getMinAboveZero(focused.cellIdx + direction, cellCount),
  }
}

const transitionDown = curry(createNewFocus, "down")
const transitionUp = curry(createNewFocus, "up")

const notebookSlice = createSlice({
  initialState,
  name: "notebook",
  reducers: {
    toggleAutoScroll: draft => {
      draft.notebook.autoScroll = !draft.notebook.autoScroll
    },
    toggleDisableMoveCursor: draft => {
      draft.notebook.disableMoveCursor = !draft.notebook.disableMoveCursor
    },
    focusCell: (draft, { payload }) => {
      switch (typeof payload.node) {
        case "undefined":
          draft.notebook.focused = {
            node: null,
            cellIdx: null,
            source: payload.source,
            target: payload.target,
          }

          break
        case "string":
          draft.notebook.focused = {
            node: payload.node,
            cellIdx: payload.cellIdx,
            source: null,
            target: null,
          }

          break
        default:
          return
      }
    },
    focusIndex: (draft, { payload }) => {
      draft.notebook.focused.cellIdx = payload.index
    },
    focusDown: draft => {
      draft.notebook.focused = transitionDown(draft)
    },
    focusUp: draft => {
      draft.notebook.focused = transitionUp(draft)
    },
    clearFocus: draft => {
      draft.notebook.focused = initialState.notebook.focused
    },
    setCommandMode: draft => {
      draft.notebook.mode = "command"
    },
    setEditMode: draft => {
      draft.notebook.mode = "edit"
    },
    pauseAutosave: draft => {
      draft.autosave.paused = true
    },
    unpauseAutosave: draft => {
      draft.autosave.paused = false
    },
    appendNode: (draft, { payload }) => {
      draft.workflow.spec.nodes.push(payload)
      draft.workflow.spec.nodes = uniqBy(draft.workflow.spec.nodes, "id")
    },
    layoutBoth: draft => {
      draft.notebook.layout.selected = "both"
      draft.notebook.layout.codeWidth = 60
      draft.notebook.layout.graphWidth = 40
    },
    layoutCode: draft => {
      draft.notebook.layout.selected = "code"
      draft.notebook.layout.codeWidth = 100
      draft.notebook.layout.graphWidth = 0
    },
    layoutGraph: draft => {
      draft.notebook.layout.selected = "graph"
      draft.notebook.layout.codeWidth = 0
      draft.notebook.layout.graphWidth = 100
    },
    setCellCodeVisibility: (draft, { payload }) => {
      const { cellId, visible } = payload
      const { cell } = getCellById(cellId, draft)
      if (cell) cell.metadata.showCode = visible
    },
    setCellOutputVisibility: (draft, { payload }) => {
      const { cellId, visible } = payload
      const { cell } = getCellById(cellId, draft)
      if (cell) cell.metadata.showOutput = visible
    },
    setAllCellCode: (draft, { payload }) => {
      draft.workflow.spec.nodes.forEach((node, nodeIdx) => {
        if (node.type !== "script") return
        node.cells.forEach((cell, cellIdx) => {
          const path = [...nodesPath, nodeIdx, "cells", cellIdx].join(".")
          set(draft, `${path}.metadata.showCode`, payload.value)
        })
      })
    },
    setAllCellOutputs: (draft, { payload }) => {
      draft.workflow.spec.nodes.forEach((node, nodeIdx) => {
        if (node.type !== "script") return
        node.cells.forEach((cell, cellIdx) => {
          const path = [...nodesPath, nodeIdx, "cells", cellIdx].join(".")
          set(draft, `${path}.metadata.showOutput`, payload.value)
        })
      })
    },
    sortNodes: draft => {
      draft.workflow.spec.nodes = nodeSort(draft.workflow)
    },
    syncNodeMetadata: (draft, { payload }) => {
      const { id, cells, ...metadata } = payload
      const updatedNodes = draft.workflow.spec.nodes.map(node => {
        if (node.id === id) return { ...node, ...metadata }
        return filterTypenames(node)
      })

      draft.workflow.spec.nodes = updatedNodes
    },
    syncEdges: (draft, { payload }) => {
      if (Array.isArray(payload)) {
        draft.workflow.spec.edges = payload.map(filterTypenames)
        return
      }

      return draft
    },
    syncCellsForNode: (draft, { payload }) => {
      const { nodeId, cells } = payload
      const { nodes } = draft.workflow.spec
      const node = getNodeById(nodeId, nodes)
      if (!node) {
        return draft
      }

      node.cells = cells.map(filterTypenames)
    },
    removeNodeById: (draft, { payload }) => {
      const { nodeId } = payload
      const newNodeArray = get(draft, nodesPath).filter(
        node => node.id !== nodeId
      )
      draft.workflow.spec.nodes = newNodeArray
    },
    setCellStatus: (draft, { payload }) => {
      const currentStatus = draft.notebook.cellStatuses[payload.cellId] || {}
      //logically, the waiting state occurs before a cell can toggle the
      //loading state. if the waiting state is false, then the loading state may
      //have been misfired. This can happen if a cell is executed and an 'execute_input'
      //msg is received after the 'execute_reply' msg
      if (payload.status === "loading" && currentStatus !== "waiting") return
      draft.notebook.cellStatuses[payload.cellId] = payload.status
    },
    stopWaitingCellStatuses: draft => {
      const {
        notebook: { cellStatuses },
      } = draft
      draft.notebook.cellStatuses = Object.entries(cellStatuses).reduce(
        (acc, [cellId, status]) => {
          if (status === "waiting") {
            return { ...acc, [cellId]: "ok" }
          }
          return { ...acc, [cellId]: status }
        },
        {}
      )
    },
    loadWorkflow: (draft, { payload }) => {
      draft.workflow = payload
      draft.isDefaultState = false
    },
    setNodeDimensions: (draft, { payload }) => {
      const { id } = payload
      const node = getNodeById(id, draft.workflow.spec.nodes)
      if (!node.style) {
        node.style = {}
      }
      node.height = payload.height || node.height
      node.width = payload.width || node.width
      node.style.height = payload.style?.height || node.style.height
      node.style.width = payload.style?.width || node.style.width
    },
    addNodeOutputs: (draft, { payload }) => {
      const { nodeId, outputs } = payload
      const { nodes } = draft.workflow.spec
      const node = getNodeById(nodeId, nodes)
      const mapOutputs = ([title, type]) => ({ id: title, title, type })

      if (!node) return draft

      node.artifacts = uniqBy(
        [...Object.entries(outputs || []).map(mapOutputs), ...node.artifacts],
        ({ title }) => title
      )
    },
    clearNodeOutputs: (draft, { payload }) => {
      const { nodeId } = payload
      const { nodes } = draft.workflow.spec
      const node = getNodeById(nodeId, nodes)

      if (!node) return draft

      node.artifacts = []
    },
    addScriptArgument: (draft, { payload }) => {
      const { nodeId, argument } = payload
      const { nodes } = draft.workflow.spec
      const node = getNodeById(nodeId, nodes)

      if (!isScriptNode(node)) return draft

      if (node.arguments) {
        node.arguments.push(argument)
        return
      }

      node.arguments = [argument]
    },
    removeScriptArgument: (draft, { payload }) => {
      const { nodeId, argumentName } = payload
      const { nodes } = draft.workflow.spec
      const node = getNodeById(nodeId, nodes)

      if (!isScriptNode(node)) return draft

      const filteredArgs = node.arguments.filter(
        arg => arg.name !== argumentName
      )
      node.arguments = filteredArgs
    },
    removeParameters: (draft, { payload }) => {
      const { argumentNames = [], artifactIds = [] } = payload
      const { edges } = draft.workflow.spec

      edges.forEach(edge => {
        const { parameters = [] } = edge
        const newParams = parameters.filter(param => {
          const containsArgument = argumentNames.includes(param.argument)
          const containsArtifact = artifactIds.includes(param.artifact)
          return !containsArtifact && !containsArgument
        })

        edge.parameters = newParams
      })
    },
    assignParameter: (draft, { payload }) => {
      const { argumentName, artifactId, targetId } = payload
      const { nodes, edges } = draft.workflow.spec
      const sourceNode = findOutputSource(artifactId, nodes)
      if (!sourceNode) return draft

      const edge = getConnectingEdge(
        { sourceId: sourceNode.id, targetId },
        edges
      )

      if (!edge) return draft

      const { parameters = [] } = edge
      edge.parameters = uniqBy(
        [
          {
            argument: argumentName,
            artifactId,
          },
          ...parameters,
        ],
        "argument"
      )
    },
    setHistoryPanelClosed: draft => {
      draft.notebook.historyPanelOpen = false
    },
    setHistoryPanelOpen: draft => {
      draft.notebook.historyPanelOpen = true
    },
    setIsLoading: (draft, { payload }) => {
      draft.isLoading = payload
    },
    toggleMuteForCell: (draft, { payload }) => {
      const { cellId } = payload
      const { cell } = getCellById(cellId, draft)
      cell.metadata.muted = !cell.metadata.muted
    },
    setCellRunTime: (draft, { payload }) => {
      const { cellId, runTime } = payload
      const { cell } = getCellById(cellId, draft)
      if (cell) cell.metadata.runTime = runTime
    },
    setCellSource: (draft, { payload }) => {
      const { nodeIdx, cellIdx, sourceValue } = payload
      const cell = draft.workflow.spec.nodes[nodeIdx].cells[cellIdx]
      if (cell) cell.source = [sourceValue]
    },
    ...createCommonActions(initialState, { setLastChange: true }),
  },
})

const { actions } = notebookSlice
const actionsAndThunks = createThunks(actions)

export { actionsAndThunks as actions }
export default notebookSlice
