import React, { useCallback, useState, useEffect } from "react"
import { useDispatch } from "react-redux"

import { Select, Input } from "antd"
import styled from "styled-components"

import { actions } from "reducers/scheduleReducer"
import { Flex, FlexLabel } from "./CronControls"

const { Option } = Select

const Presets = ({ onChange }) => {
  const dispatch = useDispatch()
  const [value, setValue] = useState(1)
  const [unit, setUnit] = useState("minute")
  const [time, setTime] = useState(null)
  const [date, setDate] = useState(null)
  const [selectedDay, setSelectedDay] = useState(1)

  const handleUnitChange = useCallback(
    newUnit => {
      setUnit(newUnit)
      setValue(1)
      setTime(null)
      setDate(null)
      setSelectedDay(1)
      dispatch(actions.resetSchedule())
      onChange(1, newUnit, null, null)
    },
    [onChange, dispatch]
  )

  const handleNumberChange = useCallback(
    e => {
      const newValue = e.target.value
      setValue(newValue)
      onChange(newValue, unit, time, date)
    },
    [onChange, unit, time, date]
  )

  const handleDateChange = useCallback(
    day => {
      setSelectedDay(day)
      onChange(value, unit, time, day)
    },
    [onChange, value, unit, time]
  )

  useEffect(() => {
    let cron = ""
    let cronText = ""

    if (unit === "minute") {
      cron = `*/${value} * * * *`
      cronText = `Every ${value} minute(s)`
      dispatch(actions.setMinuteStep({ idx: 0, step: value }))
      dispatch(actions.setHourStart({ idx: 0, start: "*" }))
      dispatch(actions.setDomStart({ idx: 0, start: "*" }))
      dispatch(actions.setMonthStart({ idx: 0, start: "*" }))
    } else if (unit === "hour") {
      cron = `0 */${value} * * *`
      cronText = `Every ${value} hour(s)`
      dispatch(actions.setMinuteStep({ idx: 0, step: null }))
      dispatch(actions.setHourStep({ idx: 0, step: value }))
      dispatch(actions.setHourStart({ idx: 0, start: "0" }))
      dispatch(actions.setDomStart({ idx: 0, start: "*" }))
      dispatch(actions.setMonthStart({ idx: 0, start: "*" }))
    } else if (unit === "day") {
      cron = `0 0 ${selectedDay || 1}/${value} * *`
      cronText = `Every ${value} day(s)`
      dispatch(actions.setDomStep({ idx: 0, step: value }))
      dispatch(
        actions.setDomStart({ idx: 0, start: String(selectedDay) || "1" })
      )
      dispatch(actions.setMinuteStep({ idx: 0, step: null }))
      dispatch(actions.setHourStep({ idx: 0, step: null }))
      dispatch(actions.setHourStart({ idx: 0, start: "0" }))
      dispatch(actions.setMonthStart({ idx: 0, start: "*" }))
    } else if (unit === "month") {
      cron = `0 0 ${selectedDay} */${value} *`
      cronText = `Every ${value} month(s) on day ${selectedDay} of the month`
      dispatch(actions.setMonthStep({ idx: 0, step: value }))
      dispatch(actions.setDomStart({ idx: 0, start: String(selectedDay) }))
      dispatch(actions.setMinuteStep({ idx: 0, step: null }))
      dispatch(actions.setHourStep({ idx: 0, step: null }))
      dispatch(actions.setHourStart({ idx: 0, start: "0" }))
    }
    onChange(value, unit, time, selectedDay, cron, cronText)
  }, [value, unit, time, date, selectedDay, onChange, dispatch])

  const StyledLabel = styled.label`
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 0;
    padding-right: 5px;
    padding-left: 10px;
  `

  return (
    <Flex className="form-group">
      <FlexLabel className="form-label">Repeat Every</FlexLabel>
      <Input
        type="number"
        min={1}
        value={value}
        onChange={handleNumberChange}
        style={{ width: 80, marginLeft: 10 }}
      />
      <Select
        defaultValue="minute"
        style={{ width: 120 }}
        onChange={handleUnitChange}
      >
        <Option value="minute">Minutes</Option>
        <Option value="hour">Hours</Option>
        <Option value="day">Days</Option>
        <Option value="month">Months</Option>
      </Select>
      {unit === "day" || unit === "month" ? (
        <div>
          <StyledLabel>starting on day </StyledLabel>
          <Select
            defaultValue={selectedDay}
            style={{ width: 70 }}
            onChange={handleDateChange}
          >
            {[...Array(31).keys()].map(i => (
              <Option key={i + 1} value={i + 1}>
                {i + 1}
              </Option>
            ))}
          </Select>
          <StyledLabel> of the month</StyledLabel>
        </div>
      ) : null}
    </Flex>
  )
}

export default Presets
