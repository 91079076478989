const lightThemeColors = {
  colorPrimary: "#9aacb4",
  colorInfo: "#646767",
  colorWarning: "#f5bf11",
  colorError: "#B61215",
  colorSuccess: "#56b44d",
  colorBgBase: "#eeeeee",

  // custom color tokens
  colorHeader: "#242833",
  textEditorTheme: "default",
  dropzoneHeader: "#cccccc",
  nodeBg: "#f2f2f5",
  emptyNodeBg: "#f2f2f580",
  nodeColor: "#222",
  nodeBorder: "#777",
  nodeBorderSelected: "#000",

  minimapMaskBg: "#fff",
  minimapMaskNode: "#f2f2f5",
  minimapMaskFill: "rgba(0, 0, 0, 0.2)",

  controlsBg: "#fefefe",
  controlsBgHover: "#eee",
  controlsColor: "#222",
  controlsBorder: "#ddd",
  //Theme Mode
  themeMode: "light",
}

const darkThemeColors = {
  colorPrimary: "#9aacb4",
  colorTextBase: "#ffffff",
  colorWarning: "#f5bf11",
  colorError: "#b61215",
  colorSuccess: "#56b44d",
  colorInfo: "#C9E3EA",
  colorBgBase: "#151515",

  // custom color tokens
  colorHeader: "#2c2c2c",
  textEditorTheme: "material-darker",
  dropzoneHeader: "#555555",

  nodeBg: "#343435",
  emptyNodeBg: "#34343480",
  nodeColor: "#f9f9f9",
  nodeBorder: "#aaa",
  nodeBorderSelected: "#fff",

  minimapMaskBg: "#000",
  minimapMaskNode: "#343435",
  minimapMaskFill: "rgba(255, 255, 255, 0.1)",

  controlsBg: "#555",
  controlsBgHover: "#676768",
  controlsColor: "#dddddd",
  controlsBorder: "#676768",
  //Theme Mode
  themeMode: "dark",
}

const getSharableTheme = (token, themeMode) => {
  return {
    ...(token || {}),
    primary: token?.colorPrimary,
    fill: token?.colorFill,
    text: token?.colorText,
    textSecondary: token?.colorTextSecondary,
    background: token?.colorBgBase,
    danger: token?.colorError,
    warning: token?.colorWarning,
    success: token?.colorSuccess,
    neutral: token?.colorBgLayout,
    linkActive: token?.colorLinkActive,
    header: token?.colorHeader,
    themeMode,
  }
}

export { getSharableTheme, lightThemeColors, darkThemeColors }
