import { set } from "lodash"
import { createSlice } from "@reduxjs/toolkit"
import { merge, isEqual, pick } from "lodash"

import {
  uuidv4,
  arraySwap,
  storeToState,
  createCommonActions,
  createResourceActions,
  alphanumid,
  toastAndRethrow,
} from "@dbai/tool-box"
import { getJwt, selectCurrentCustomerNormalizedName } from "@dbai/ui-staples"

import { selectForm } from "../selectors/forms"
import { findFilterIndex } from "../lib/filters"
import { normalizeFormData } from "../lib/formData"
import { actions as formActions } from "./formsReducer"
import { NEW_WIDGET_FORM_ID, getWidgetFormId } from "../lib/widgetEditor"
import {
  DROPPING_ITEM_I,
  getDefaultLayout,
  generateFullBreakpointLayout,
} from "../lib/layout"
import {
  selectApp,
  selectAppBreakpoint,
  selectAppEndpoint,
  selectAppPage,
  selectAppVariables,
  selectAppWidget,
  selectAppWidgetLayout,
  selectCopiedWidget,
  selectEditingWidgetId,
  selectRootPageId,
  selectCrossFilters,
  selectWidgetEditorPageId,
} from "../selectors/app"
import {
  mergeYMap,
  postYjs,
  addPageYDoc,
  addWidgetYDoc,
  moveYArrayItem,
  removeYMapItem,
  addYMapToYArray,
  updateYArrayItem,
  removeYArrayItem,
  removeWidgetYDoc,
  addWidgetToPageYDoc,
  removeWidgetSelectionYDoc,
  addWidgetSelectionYDoc,
} from "../lib/yjs"
import { CREATE_APP_SNAPSHOT } from "../queries/app"

const appSections = [
  "showAppFilters",
  "showAppSettings",
  "showAppVariables",
  "showAppEndpoints",
  "showAppEndpoint",
]

const toggleAppSection = (draft, value, section) => {
  if ([null, undefined].includes(value)) {
    draft.widgetEditor[section] = !draft.widgetEditor[section]
  } else {
    draft.widgetEditor[section] = value
  }
  appSections.forEach(s => {
    if (s !== section) {
      draft.widgetEditor[s] = false
    }
  })
}

const load = (draft, payload) => {
  draft.id = payload.id
  draft.name = payload.name
  draft.pageId = payload.pageId

  const {
    logo,
    themeId,
    rowHeight,
    allowOverlap,
    compactionMethod,
    enablePageHeader,
    ...rest
  } = payload.spec || {}
  draft.spec = storeToState({
    ...defaultSpec,
    ...rest,
    pages: payload.spec.pages || {},
    widgets: payload.spec.widgets || {},
    title: payload.spec?.title ? payload.spec.title : payload.name, // default Page Header title to Delta App name
    theme: {
      logo, // Added for backwards compatibility
      themeId, // Added for backwards compatibility
      rowHeight, // Added for backwards compatibility
      allowOverlap, // Added for backwards compatibility
      enablePageHeader, // Added for backwards compatibility
      compactionMethod, // Added for backwards compatibility
      ...payload.spec.theme,
    },
  })
}
const defaultSpec = {
  theme: {},
  pages: {},
  title: null,
  widgets: {},
  filters: [],
  endpoints: [],
  variables: [],
  description: "",
}

const initialWidgetEditor = {
  open: false,
  formId: null,
  pageId: null,
  widgetId: null,
  endpointId: null,
  copyWidgetId: null,
  showAppSettings: false,
  showAppVariables: false,
  showAppFilters: false,
  showAppEndpoints: false,
  allowFullEditor: false,
}

const initialState = {
  id: null,
  name: "",
  saveStatus: "idle",
  breakpoint: null,
  spec: defaultSpec,
  showPageGridLines: false,
  widgetEditor: initialWidgetEditor,
  sessionFilters: {},
  selectedPoints: [],
  selectedWidgets: {},
  contextMenu: {
    widgetId: null,
    pages: {},
  },
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    load: (draft, { payload }) => {
      load(draft, payload)
    },
    toggleShowPageGridLines: draft => {
      draft.showPageGridLines = !draft.showPageGridLines
    },
    mergeSpec: (draft, { payload }) => {
      draft.spec = { ...draft.spec, ...payload }
    },
    setSpec: (draft, { payload }) => {
      draft.spec = payload
    },
    setBreakpoint: (draft, { payload }) => {
      draft.breakpoint = payload
    },
    setAppStatus: (draft, { payload }) => {
      draft.status = payload
    },

    /**
     *
     *
     *
     * APP THEME
     *
     *
     *
     */
    setAppTheme: (draft, { payload }) => {
      draft.spec.theme = payload
    },

    /**
     *
     *
     * APP FILTERS
     *
     *
     */
    setAppFilters: (draft, { payload }) => {
      draft.spec.filters = payload
    },
    addAppFilter: (draft, { payload }) => {
      draft.spec.filters.push(payload)
    },
    moveAppFilter: (draft, { payload }) => {
      const { fromIndex, toIndex } = payload
      const item = draft.spec.filters.splice(fromIndex, 1)[0]
      draft.spec.filters.splice(toIndex, 0, item)
    },
    setAppFilter: (draft, { payload }) => {
      const { index, filter } = payload

      // old filters do not contain an id. the index is passed here so that this action doesnt fully rely on the filter id
      if (index >= 0) {
        draft.spec.filters[index] = {
          ...draft.spec.filters[index],
          ...filter,
        }
        return
      }

      if (!filter.id) {
        console.error("[setAppFilter] - App filter id is required")
        return
      }

      const filterIndex = draft.spec.filters.findIndex(v => v.id === filter.id)

      if (filterIndex < 0) {
        console.error(
          `[setAppFilter] - App filter with id ${filter.id} not found`
        )
        return
      }

      draft.spec.filters[filterIndex] = {
        ...draft.spec.filters[filterIndex],
        ...filter,
      }
    },
    removeAppFilter: (draft, { payload }) => {
      const { index, id } = payload
      if (!id && [null, undefined].includes(index)) {
        console.error(
          `[removeAppFilter] - An 'id' or 'index' field is required to remove app filter`
        )
        return
      }

      if (id && draft.spec.filters.find(v => v.id === id)) {
        draft.spec.filters = draft.spec.filters.filter(v => v.id !== id)
        return
      }

      if (index > -1) {
        delete draft.spec.filters[index]
      }
    },

    /**
     *
     *
     * APP VARIABLES
     *
     *
     */
    setAppVariables: (draft, { payload }) => {
      // if the payload length is the same as the current variables length, we can assume that the entire array is being replaced
      if (payload.length === draft.spec.variables.length) {
        draft.spec.variables = payload
        return
      }

      payload.forEach(({ id, ...variableData }) => {
        const variableIndex = draft.spec.variables.findIndex(v => v.id === id)

        if (variableIndex < 0) {
          console.error(
            `[setAppVariable] - App variable with id ${id} not found`
          )
          return
        }

        draft.spec.variables[variableIndex] = {
          ...draft.spec.variables[variableIndex],
          ...variableData,
        }
      })
    },
    moveAppVariable: (draft, { payload }) => {
      const { fromIndex, toIndex } = payload
      const item = draft.spec.variables.splice(fromIndex, 1)[0]
      draft.spec.variables.splice(toIndex, 0, item)
    },
    addAppVariable: (draft, { payload }) => {
      draft.spec.variables = [
        ...draft.spec.variables,
        { id: uuidv4(), ...payload },
      ]
    },
    setAppVariable: (draft, { payload }) => {
      if (!payload.id) {
        console.error("[setAppVariable] - App variable id is required")
        return
      }

      const variableIndex = draft.spec.variables.findIndex(
        v => v.id === payload.id
      )

      if (variableIndex < 0) {
        console.error(
          `[setAppVariable] - App variable with id ${payload.id} not found`
        )
        return
      }

      draft.spec.variables[variableIndex] = {
        ...draft.spec.variables[variableIndex],
        ...payload,
      }
    },
    removeAppVariable: (draft, { payload }) => {
      const { index, id } = payload
      if (!id || [null, undefined].includes(index)) {
        console.error(
          `[removeAppVariable] - An 'id' or 'index' field is required to remove app variable`
        )
        return
      }

      if (id) {
        draft.spec.variables = draft.spec.variables.filter(v => v.id !== id)
        return
      }

      if (index > -1) {
        delete draft.spec.variables[index]
      }
    },

    /**
     *
     *
     * CONTEXT MENU
     *
     *
     */
    addPageToContextMenu: (draft, { payload }) => {
      const { pageId, x, y, name } = payload
      draft.contextMenu.pages = {
        ...draft.contextMenu.pages,
        [pageId]: { x, y, name },
      }
    },
    addWidgetToContextMenu: (draft, { payload }) => {
      const { widgetId } = payload
      draft.contextMenu.widgetId = widgetId
    },
    removeContext: draft => {
      draft.contextMenu = initialState.contextMenu
    },

    /**
     *
     *
     * PAGE
     *
     *
     */
    addPage: (draft, { payload }) => {
      const { pageId, name } = payload
      draft.spec.pages[pageId] = {
        name,
        widgetIds: [],
      }
    },
    removePage: (draft, { payload }) => {
      delete draft.spec.pages[payload]
    },
    renamePage: (draft, { payload }) => {
      const { pageId, name } = payload
      draft.spec.pages[pageId].name = name
    },
    swapPageOrder: (draft, { payload }) => {
      const { pageId, from, to } = payload
      const page = draft.spec.pages[pageId]
      page.widgetIds = arraySwap(page.widgetIds, from, to)
    },
    setPageWidgetIds: (draft, { payload }) => {
      const { pageId, widgetIds } = payload
      draft.spec.pages[pageId].widgetIds = widgetIds
    },

    /**
     *
     *
     * WIDGET
     *
     *
     */
    setWidget: (draft, { payload }) => {
      const { widgetId, name, value } = payload
      if (!name) {
        draft.spec.widgets[widgetId] = value
        return
      }
      set(draft.spec.widgets[widgetId], name, value)
    },
    setWidgetOptions: (draft, { payload }) => {
      const { value, name, widgetId } = payload
      if (name) {
        set(draft.spec.widgets[widgetId], `options.${name}`, value)
        return
      }

      Object.entries(value).forEach(([k, v]) => {
        draft.spec.widgets[widgetId].options[k] = v
      })
    },
    setWidgetLayout: (draft, { payload }) => {
      draft.spec.widgets[payload.widgetId].layout = payload.value
    },
    addWidget: (draft, { payload }) => {
      const {
        widgetId,
        widget,
        pageId,
        closeEditor,
        editWidget,
        allowFullEditor,
      } = payload

      if (pageId) {
        const page = draft.spec.pages[pageId]
        draft.spec.pages[pageId].widgetIds = [...page.widgetIds, widgetId]
      }

      const widgetWithId = { ...widget, id: widgetId }
      draft.spec.widgets[widgetId] = widgetWithId
      draft.widgetEditor.allowFullEditor = allowFullEditor

      if (closeEditor) {
        draft.widgetEditor.open = false
      }
      if (editWidget) {
        draft.widgetEditor.widgetId = widgetId
      }
    },
    removeWidget: (draft, { payload }) => {
      const { pageId, widgetId } = payload

      if (pageId) {
        draft.spec.pages[pageId].widgetIds = draft.spec.pages[
          pageId
        ].widgetIds.filter(i => i !== widgetId)
      } else {
        // find pages with widgetId
        Object.values(draft.spec.pages).forEach(page => {
          page.widgetIds = page.widgetIds.filter(wId => wId !== widgetId)
        })
      }

      // recursively remove nested pages and widgets
      const removePages = navItems => {
        if (!navItems?.length) return
        navItems.forEach(({ pageId }) => {
          const widgetIds = draft.spec.pages[pageId]?.widgetIds
          widgetIds?.forEach(wId => {
            const nestedWidget = draft.spec.widgets[wId]
            removePages(nestedWidget.options?.navItems)
            delete draft.spec.widgets[wId]
          })
          delete draft.spec.pages[pageId]
        })
      }

      const widget = draft.spec.widgets[widgetId]
      const navItems = widget.options?.navItems
      removePages(navItems)

      delete draft.spec.widgets[widgetId]

      const editingWidgetId = draft.widgetEditor.widgetId
      if (editingWidgetId === widgetId) {
        draft.widgetEditor.widgetId = null
      }
    },
    publishWidget: (draft, { payload }) => {
      draft.spec.widgets[payload.id] = payload
      draft.widgetEditor = initialWidgetEditor
    },
    setActiveKey: (draft, { payload }) => {
      draft.widgets[payload.widgetId].meta = {
        ...(draft.widgets[payload.widgetId].meta || {}),
        activeKey: payload.activeKey,
      }
    },

    /**
     *
     *
     * SIDE PANEL
     *
     *
     */
    toggleShowAppSettings: (draft, { payload }) => {
      toggleAppSection(draft, payload, "showAppSettings")
    },
    toggleShowAppFilters: (draft, { payload }) => {
      toggleAppSection(draft, payload, "showAppFilters")
    },
    toggleShowAppVariables: (draft, { payload }) => {
      toggleAppSection(draft, payload, "showAppVariables")
    },
    toggleShowEndpoints: (draft, { payload }) => {
      toggleAppSection(draft, payload, "showAppEndpoints")
    },
    toggleShowEndpoint: (draft, { payload }) => {
      toggleAppSection(draft, true, "showAppEndpoint")
      draft.widgetEditor.endpointId = payload.endpointId
    },
    hideAllSettings: draft => {
      if (draft.widgetEditor.showAppEndpoint) {
        draft.widgetEditor.showAppEndpoint = false
        draft.widgetEditor.showAppEndpoints = true
        return
      }
      draft.widgetEditor.showAppFilters = false
      draft.widgetEditor.showAppSettings = false
      draft.widgetEditor.showAppEndpoint = false
      draft.widgetEditor.showAppVariables = false
      draft.widgetEditor.showAppEndpoints = false
    },
    openWidgetEditor: draft => {
      draft.widgetEditor.open = true
    },
    closeEditor: draft => {
      draft.widgetEditor = initialWidgetEditor
    },
    copyWidget: (draft, { payload }) => {
      draft.widgetEditor.copyWidgetId = payload.widgetId
    },
    selectWidget: (draft, { payload }) => {
      const { pageId, widgetId, allowFullEditor } = payload
      // first, move selected widget to the top
      const page = draft.spec.pages[pageId]
      const lastIndex = page.widgetIds.length - 1
      const currentIndex = page.widgetIds.findIndex(i => i === widgetId)
      page.widgetIds = arraySwap(page.widgetIds, currentIndex, lastIndex)

      // then, open widget editor for selected widget
      draft.widgetEditor.widgetId = widgetId
      draft.widgetEditor.allowFullEditor = allowFullEditor
    },

    editWidget: (draft, { payload }) => {
      draft.widgetEditor.pageId = payload.pageId
      draft.widgetEditor.widgetId = payload.widgetId
      draft.widgetEditor.open = payload.openFullEditor
      draft.widgetEditor.allowFullEditor = payload.allowFullEditor
    },

    /**
     *
     *
     * SESSION FILTERS
     *
     *
     */
    saveSessionFilter: (draft, { payload }) => {
      const { datasetId, column, op, id } = payload
      if (!datasetId || !column || !op) return
      if (!draft.sessionFilters[datasetId]?.length) {
        draft.sessionFilters[datasetId] = []
      }

      const existingFilterIndex = findFilterIndex(
        draft.sessionFilters[datasetId],
        id
      )

      if (existingFilterIndex !== -1) {
        draft.sessionFilters[datasetId][existingFilterIndex] = payload
        return
      }
      draft.sessionFilters[datasetId].push(payload)
    },
    removeSessionFilter: (draft, { payload }) => {
      const { id } = payload
      if (!id) return

      Object.entries(draft.sessionFilters).forEach(
        ([datasetId, sessionFilters]) => {
          const existingFilterIndex = findFilterIndex(sessionFilters, id)
          if (existingFilterIndex === -1) return
          draft.sessionFilters[datasetId].splice(existingFilterIndex, 1)
        }
      )
    },
    clearSessionFilters: draft => {
      draft.sessionFilters = {}
    },

    /**
     *
     *
     *  ENDPOINTS
     *
     *
     */
    addAppEndpoint: (draft, { payload }) => {
      const { id, name } = payload
      draft.spec.endpoints.push({ id, name })
    },
    updateAppEndpoint: (draft, { payload }) => {
      const index = draft.spec.endpoints.findIndex(e => e.id === payload.id)
      if (index < 0) return
      draft.spec.endpoints[index] = {
        ...draft.spec.endpoints[index],
        ...payload,
      }
    },
    removeAppEndpoint: (draft, { payload }) => {
      const index = draft.spec.endpoints.findIndex(
        e => e.id === payload.endpointId
      )
      if (index < 0) return
      draft.spec.endpoints.splice(index, 1)
    },

    /**
     *
     *
     * SELECTED POINTS
     *
     *
     */
    setSelectedPoint: (draft, { payload }) => {
      const {
        value,
        // pageId
      } = payload
      // TODO: maintain filters for each page when chart render bug is fixed.
      // const filteredPoints = draft.selectedPoints.filter(
      //   p => p.pageId !== pageId
      // )
      draft.selectedPoints = value //[...filteredPoints, ...value]
    },

    /**
     *
     *
     * YJS ACTIONS
     *
     *
     */
    setSelectedWidgets: (draft, { payload }) => {
      draft.selectedWidgets = payload
    },
    setSaveStatus: (draft, { payload }) => {
      draft.saveStatus = payload.value
    },
    setYMetadata: (draft, { payload }) => {
      const { saveStatus, selections } = payload
      draft.saveStatus = saveStatus || "idle"
      draft.selectedWidgets = selections || {}
    },

    ...createResourceActions({ initialState }),
    ...createCommonActions(initialState),
  },
})

const actions = appSlice.actions

const replacer = (key, value) => (typeof value === "undefined" ? null : value)

const getSelectedPoints = (points, { widgetId, pageId, datasetId }) => {
  return points
    .filter(point => point.column) // only select points that have a dataset column
    .map(point => ({ widgetId, pageId, datasetId, ...point })) // add widgetId and pageId to each point, but only if they don't already exist
}

const generateNewWidgetLayout = widget => {
  const { id, layout } = widget
  /**
   * The initial widget state should already have a layout property with a w/h property set
   * for each breakpoint. In some cases, the x/y property will also be set initially. In the
   * case that it isn't, randomized x/y values are generated here
   */
  const layoutWithXY = getDefaultLayout()

  /**
   * Generate a layout with the each breakpoint's identifier 'i' property
   * set to the widgetId
   */
  const layoutWithI = generateFullBreakpointLayout({ i: id })

  /**
   * mergedLayout takes the inital widget layout and merges the other computed layouts on top of it,
   * ensuring that the initial layout has all the necessary properties
   */
  const mergedLayout = merge({}, layoutWithXY, layout || {}, layoutWithI)
  return mergedLayout
}

const getInputParams = (inputSpec, appVariables) => {
  return Object.entries(inputSpec).reduce((acc, [key, value]) => {
    if (typeof value === "object") {
      return {
        ...acc,
        [key]: getInputParams(value, appVariables),
      }
    }
    const appVariable = appVariables?.find(v => v.id === value)
    if (Boolean(appVariable)) {
      return {
        ...acc,
        [key]: appVariable.value,
      }
    }
    return { ...acc, [key]: value }
  }, {})
}

const processEndpointResults = (dispatch, results, outputSpec) => {
  if (!outputSpec) return
  return Object.entries(outputSpec).forEach(([key, value]) => {
    if (value) {
      dispatch(setAppVariableWithSync({ ...results[key], id: value }))
    }
  })
}

const getEditingWidgetFormId = state => {
  const widgetId = selectEditingWidgetId(state)
  return getWidgetFormId(widgetId)
}

const loadWidgetForm = ({ widgetId, schema }) => {
  return async (dispatch, getState) => {
    const widget = selectAppWidget(getState(), { widgetId })
    if (!widget) return Promise.resolve()

    const formId = getWidgetFormId(widgetId)

    return await new Promise(resolve => {
      requestIdleCallback(() => {
        dispatch(
          formActions.loadFormData({
            schema,
            id: formId,
            value: widget,
            validate: true,
            initialState: widget,
          })
        ).then(resolve)
      })
    })
  }
}

const copyNavWidget = ({ widget, pageId, widgetSchema }) => {
  return dispatch => {
    const navItems = dispatch(
      copyPages({
        widgetSchema,
        navItems: widget.options.navItems,
      })
    )

    return dispatch(
      spawnNewWidget({
        widgetSchema,
        pageId,
        widget: {
          ...widget,
          options: { ...widget.options, navItems },
        },
      })
    )
  }
}

const copyPages = ({ navItems, widgetSchema }) => {
  return (dispatch, getState) => {
    const state = getState()

    // copy nested pages and widgets recursively
    return navItems.map(navItem => {
      // for each nav item, spawn a new page
      const newPageId = uuidv4()
      const newPage = { pageId: newPageId, name: navItem.title }
      const page = selectAppPage(state, { pageId: navItem.pageId })
      dispatch(addPageWithSync(newPage))

      // for each widget nested in the page, spawn a new widget and connect it to the page that was just added
      const widgetIds = page.widgetIds
      widgetIds.map(widgetId => {
        const widget = selectAppWidget(state, { widgetId })
        // if nested widget is a container, then recursively copy its children
        if (["Page", "TabWidget"].includes(widget.type)) {
          return dispatch(
            copyNavWidget({
              widget,
              pageId: newPageId,
              widgetSchema,
            })
          )
        }

        return dispatch(
          spawnNewWidget({
            widget,
            pageId: newPageId,
            widgetSchema,
          })
        )
      })

      return { ...navItem, pageId: newPageId }
    })
  }
}

const saveLayout = ({
  layout: breakpointLayout,
  widgetId: _widgetId,

  // only include these properties when the widget metadata needs to be updated
  editable,
  widgetSchema,
}) => {
  return (dispatch, getState) => {
    const widgetId = _widgetId || breakpointLayout.i
    if (breakpointLayout.i === DROPPING_ITEM_I) return

    const state = getState()

    // when a widget is removed, the grid will attempt to save the layout one last time.
    // this check prevents the layout from saving
    if (!selectAppWidget(state, { widgetId })) return

    const breakpoint = selectAppBreakpoint(state)

    if (!breakpoint) return

    const currentLayout = selectAppWidgetLayout(state, { widgetId })

    // the saved layout will contain properties that are not editable in the widget state.
    // we remove those extra properties here
    const reducedBpLayout = pick(breakpointLayout, ["x", "y", "w", "h"])
    const updatedLayout = { [breakpoint]: reducedBpLayout }
    const newLayout = merge({}, currentLayout, updatedLayout)

    // prevent triggering unnecessary action if layout hasn't changed
    if (isEqual(newLayout, currentLayout)) return

    dispatch(setWidgetLayoutWithSync({ value: newLayout, widgetId }))

    // reload widget form state to get latest layout options
    if (editable && widgetSchema) {
      dispatch(loadWidgetForm({ widgetId, schema: widgetSchema }))
    }
  }
}

const spawnNewWidget = ({
  pageId,
  editWidget,
  closeEditor,
  widgetSchema,
  widgetId: presetWidgetId,
  widget: initialWidget = {},
  allowFullEditor = false,
}) => {
  return async (dispatch, getState) => {
    const widgetId = presetWidgetId ?? alphanumid()
    const formId = getWidgetFormId(widgetId)
    const widget = { ...initialWidget, id: widgetId }
    const layout = generateNewWidgetLayout(widget)
    const fullInitialWidgetState = { ...widget, layout }

    const spawn = async () => {
      // First we want to generate the form instance for the new widget.
      // During the form generation process, the default values are added to the widget state.
      // We want to run this before the widget is added so that the widget is added with the full state ready
      // TODO: consider decoupling generating the default widget state so that it can run before the form is generated
      return await dispatch(
        formActions.loadFormData({
          id: formId,
          value: fullInitialWidgetState,
          schema: widgetSchema,
          validate: true,
          initialState: fullInitialWidgetState,
        })
      ).then(() => {
        // Once the form is generated, take the form data, restructure it, and then add
        // the widget state to the app spec
        const state = getState()
        const { data = {} } = selectForm(state, { formId })
        const normalizedData = normalizeFormData(data)
        dispatch(
          addWidgetWithSync({
            pageId,
            widgetId,
            editWidget,
            closeEditor,
            widget: normalizedData,
            allowFullEditor: allowFullEditor,
          })
        )
      })
    }

    // This callback is queued to run when the browser reaches an idle state (this prevents the UI from stalling
    // immediately after the widget is added)
    return await spawn()
  }
}

const closeWidgetEditor = () => {
  return (dispatch, getState) => {
    const state = getState()
    const formId = getEditingWidgetFormId(state)
    dispatch(closeEditorWithSync())
    if (formId === NEW_WIDGET_FORM_ID) {
      // snapshot form state in case it needs to be reset
      dispatch(formActions.resetFormData({ formId }))
    }
  }
}

const saveLayouts =
  ({ layouts, widgetSchema, editable }) =>
  dispatch => {
    return Promise.all(
      layouts.map(layout => {
        return dispatch(saveLayout({ layout, widgetSchema, editable }))
      })
    )
  }

const publishApp = ({ name, description }) => {
  return (dispatch, getState, { client }) => {
    const state = getState()
    const { id } = selectApp(state)
    const cname = state.currentCustomer.customer?.normalizedName
    const variables = {
      id,
      name,
      cname,
      description,
    }

    return client
      .mutate({
        mutation: CREATE_APP_SNAPSHOT,
        variables,
      })
      .catch(toastAndRethrow("Error publishing App"))
  }
}

const loadAppSnapshot = ({ activeAppSnapshot }) => {
  return async dispatch => {
    dispatch(actions.load(activeAppSnapshot))
    return activeAppSnapshot
  }
}

const pasteWidget = ({ page, widgetSchema, widgetRegistry }) => {
  return (dispatch, getState) => {
    const state = getState()
    const {
      id,
      meta,
      layout: copiedWidgetLayout,
      ...copiedWidget
    } = selectCopiedWidget(state)

    // pastedWidgetLayout will contain just the x/y coordinates
    const pastedWidgetLayout = generateFullBreakpointLayout({
      x: page.x,
      y: page.y,
    })

    const allowFullEditor = widgetRegistry[copiedWidget.type].allowFullEditor

    // the copiedWidgetLayout contains the w/h for the widget.
    // merging the two layouts will give us the true initial layout for the pasted widget
    const mergedLayout = merge({}, copiedWidgetLayout, pastedWidgetLayout)

    const newWidget = { ...copiedWidget, layout: mergedLayout }
    if (["Page", "TabWidget"].includes(copiedWidget.type)) {
      // copy nested pages and widgets recursively
      return dispatch(
        copyNavWidget({
          widgetSchema,
          pageId: page.id,
          widget: copiedWidget,
        })
      )
    }

    return dispatch(
      spawnNewWidget({
        widgetSchema,
        pageId: page.id,
        widget: newWidget,
        allowFullEditor,
      })
    )
  }
}

const saveWidgetFromEditor = ({ closeEditor, widgetRegistry }) => {
  return (dispatch, getState) => {
    const state = getState()
    const formId = getEditingWidgetFormId(state)
    const rootPageId = selectRootPageId(state)
    const pageIdTarget = selectWidgetEditorPageId(state)
    const pageId = pageIdTarget ?? rootPageId

    const { data = {}, errors = [], schema } = selectForm(state, { formId })

    const widget = normalizeFormData(data)

    if (formId === NEW_WIDGET_FORM_ID) {
      const widgetId = alphanumid()
      const newWidgetFormId = getWidgetFormId(widgetId)
      const dataWithId = { ...data, id: widgetId }

      // Load widget form from new widget form
      dispatch(
        formActions.load({
          schema,
          errors,
          data: dataWithId,
          id: newWidgetFormId,
          initialData: dataWithId,
        })
      )

      const initialLayout = merge(
        {},
        widgetRegistry[widget.type]?.initialLayout || {},
        widget.layout
      )

      const layout = generateNewWidgetLayout({
        id: initialLayout,
        layout: initialLayout,
      })

      const newWidget = { ...widget, layout }

      // finally, add the widget
      dispatch(
        addWidgetWithSync({
          pageId,
          widgetId,
          closeEditor,
          editWidget: true,
          widget: newWidget,
        })
      )

      dispatch(formActions.resetFormData({ formId }))
    } else {
      dispatch(
        setWidgetOptionsWithSync({
          value: widget.options,
          widgetId: widget.id,
        })
      )
      closeEditor && dispatch(closeWidgetEditor())
    }
  }
}

const openWidgetEditor = () => {
  return (dispatch, getState) => {
    const state = getState()
    const formId = getEditingWidgetFormId(state)
    const { data } = selectForm(state, { formId })
    dispatch(actions.openWidgetEditor())

    // snapshot form state in case it needs to be reset
    dispatch(formActions.setInitialFormData({ id: formId, initialData: data }))
  }
}

const triggerEndpoint = ({ id, apiUrl }) => {
  return async (dispatch, getState) => {
    const state = getState()
    const endpoint = selectAppEndpoint(state, { id })
    const appVariables = selectAppVariables(state)
    const cname = selectCurrentCustomerNormalizedName(state)

    return fetch(`${apiUrl}endpoints/${cname}${endpoint.endpointId}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getJwt()}`,
      },
      method: "POST",
      body: JSON.stringify(
        getInputParams(endpoint.input, appVariables),
        replacer
      ),
    })
      .then(res => {
        return res.json()
      })
      .then(res => {
        processEndpointResults(dispatch, res, endpoint.output, appVariables)
      })
  }
}

const selectPoints = ({ points, pageId, widgetId, datasetId }) => {
  return dispatch => {
    const selectedPoints = getSelectedPoints(points, {
      widgetId,
      pageId,
      datasetId,
    })
    dispatch(actions.setSelectedPoint({ value: selectedPoints, pageId }))
  }
}

const appendSelectedPoints = ({ points, pageId, widgetId, datasetId }) => {
  return (dispatch, getState) => {
    const state = getState()
    const selectedPoints = selectCrossFilters(state)
    const newSelectedPoints = getSelectedPoints(points, {
      widgetId,
      pageId,
      datasetId,
    })
    dispatch(
      actions.setSelectedPoint({
        value: [...selectedPoints, ...newSelectedPoints],
      })
    )
  }
}

const selectWidgetWithSync = payload => dispatch => {
  dispatch(actions.selectWidget(payload))

  postYjs((yDoc, wsProvider) => {
    const { widgetId, pageId } = payload
    addWidgetSelectionYDoc(yDoc, wsProvider, widgetId)

    const specMap = yDoc.getMap("spec")
    const pagesMap = specMap.get("pages")
    const pageMap = pagesMap.get(pageId)
    const widgetIds = pageMap.get("widgetIds")
    const from = widgetIds.findIndex(i => i === widgetId)
    const to = widgetIds.length - 1
    pageMap.set("widgetIds", arraySwap(widgetIds, from, to))
  })
}

const closeEditorWithSync = payload => dispatch => {
  dispatch(actions.closeEditor(payload))

  postYjs((yDoc, wsProvider) => {
    const awareness = wsProvider.awareness
    const clientId = awareness.clientID
    removeWidgetSelectionYDoc(yDoc, clientId)
  })
}

const setAppThemeWithSync = payload => dispatch => {
  dispatch(actions.setAppTheme(payload))

  postYjs(yDoc => {
    const specMap = yDoc.getMap("spec")
    const themeMap = specMap.get("theme")
    mergeYMap(themeMap, payload)
  })
}

/**
 *
 *
 * PAGES
 *
 *
 */
const addPageWithSync = payload => dispatch => {
  dispatch(actions.addPage(payload))

  postYjs(yDoc => {
    const { pageId, name } = payload
    const spec = yDoc.getMap("spec")
    const pagesMap = spec.get("pages")
    addPageYDoc(pagesMap, pageId, { name, widgetIds: [] })
  })
}

const removePageWithSync = payload => dispatch => {
  dispatch(actions.removePage(payload))

  postYjs(yDoc => {
    const specMap = yDoc.getMap("spec")
    const pagesMap = specMap.get("pages")
    removeYMapItem(pagesMap, payload)
  })
}

const renamePageWithSync = payload => dispatch => {
  dispatch(actions.renamePage(payload))

  postYjs(yDoc => {
    const { pageId, name } = payload
    const specMap = yDoc.getMap("spec")
    const pagesMap = specMap.get("pages")
    const pageMap = pagesMap.get(pageId)
    pageMap.set("name", name)
  })
}

const swapPageOrderWithSync = payload => dispatch => {
  dispatch(actions.swapPageOrder(payload))

  postYjs(yDoc => {
    const { pageId, from, to } = payload
    const specMap = yDoc.getMap("spec")
    const pagesMap = specMap.get("pages")
    const pageMap = pagesMap.get(pageId)
    const widgetIds = pageMap.get("widgetIds")
    pageMap.set("widgetIds", arraySwap(widgetIds, from, to))
  })
}

/**
 *
 *
 * WIDGET
 *
 *
 */
const setWidgetWithSync = payload => dispatch => {
  dispatch(actions.setWidget(payload))

  postYjs(yDoc => {
    const { name, value, widgetId } = payload
    const spec = yDoc.getMap("spec")
    const widgetsMap = spec.get("widgets")
    const widgetMap = widgetsMap.get(widgetId)
    widgetMap.set(name, value)
  })
}

const setWidgetOptionsWithSync = payload => dispatch => {
  dispatch(actions.setWidgetOptions(payload))

  postYjs(yDoc => {
    const { value, widgetId } = payload
    const spec = yDoc.getMap("spec")
    const widgetsMap = spec.get("widgets")
    const widgetMap = widgetsMap.get(widgetId)
    const optionsMap = widgetMap.get("options")
    mergeYMap(optionsMap, value)
  })
}

const setWidgetLayoutWithSync = payload => dispatch => {
  dispatch(actions.setWidgetLayout(payload))

  postYjs(yDoc => {
    const { value, widgetId } = payload
    const safeWidgetId = widgetId || value.i
    const spec = yDoc.getMap("spec")
    const widgetsMap = spec.get("widgets")
    const widgetMap = widgetsMap.get(safeWidgetId)
    const layoutMap = widgetMap.get("layout")
    mergeYMap(layoutMap, value)
  })
}

const addWidgetWithSync = payload => dispatch => {
  dispatch(actions.addWidget(payload))

  postYjs((yDoc, wsProvider) => {
    const { pageId, widget, editWidget } = payload
    const spec = yDoc.getMap("spec")
    const widgetsMap = spec.get("widgets")
    addWidgetYDoc(widgetsMap, widget)

    const pagesMap = spec.get("pages")
    addWidgetToPageYDoc(pagesMap, pageId, widget.id)

    if (editWidget) {
      addWidgetSelectionYDoc(yDoc, wsProvider, widget.id)
    }
  })
}

const removeWidgetWithSync = payload => dispatch => {
  dispatch(actions.removeWidget(payload))

  postYjs(yDoc => {
    const { widgetId, pageId } = payload
    const spec = yDoc.getMap("spec")
    const widgetsMap = spec.get("widgets")
    const pagesMap = spec.get("pages")
    removeWidgetYDoc(widgetsMap, pagesMap, widgetId, pageId)
  })
}

/**
 *
 *
 * APP VARIABLES
 *
 *
 */
const addAppVariableWithSync = payload => dispatch => {
  dispatch(actions.addAppVariable(payload))

  postYjs(yDoc => {
    const specMap = yDoc.getMap("spec")
    const variablesArray = specMap.get("variables")
    addYMapToYArray(variablesArray, payload)
  })
}

const setAppVariableWithSync = payload => dispatch => {
  dispatch(actions.setAppVariable(payload))

  postYjs(yDoc => {
    const specMap = yDoc.getMap("spec")
    const variablesArray = specMap.get("variables")
    updateYArrayItem(variablesArray, payload)
  })
}

const setAppVariablesWithSync = payload => dispatch => {
  dispatch(actions.setAppVariables(payload))

  postYjs(yDoc => {
    const specMap = yDoc.getMap("spec")
    const variablesArray = specMap.get("variables")
    payload.forEach(v => {
      updateYArrayItem(variablesArray, v)
    })
  })
}

const moveAppVariablesWithSync = payload => dispatch => {
  dispatch(actions.moveAppVariable(payload))

  postYjs(yDoc => {
    const { fromIndex, toIndex } = payload
    const specMap = yDoc.getMap("spec")
    const variablesArray = specMap.get("variables")
    moveYArrayItem(variablesArray, fromIndex, toIndex)
  })
}

const removeAppVariableWithSync = payload => dispatch => {
  dispatch(actions.removeAppVariable(payload))

  postYjs(yDoc => {
    const { id, index } = payload
    const specMap = yDoc.getMap("spec")
    const variablesArray = specMap.get("variables")
    removeYArrayItem(variablesArray, id, index)
  })
}

/**
 *
 *
 * APP ENDPOINTS
 *
 *
 */
const addAppEndpointWithSync = payload => dispatch => {
  dispatch(actions.addAppEndpoint(payload))

  postYjs(yDoc => {
    const specMap = yDoc.getMap("spec")
    const endpointsArray = specMap.get("endpoints")
    addYMapToYArray(endpointsArray, payload)
  })
}

const updateAppEndpointWithSync = payload => dispatch => {
  dispatch(actions.updateAppEndpoint(payload))

  postYjs(yDoc => {
    const specMap = yDoc.getMap("spec")
    const endpointsArray = specMap.get("endpoints")
    updateYArrayItem(endpointsArray, payload)
  })
}

const removeAppEndpointWithSync = payload => dispatch => {
  dispatch(actions.removeAppEndpoint(payload))

  postYjs(yDoc => {
    const specMap = yDoc.getMap("spec")
    const endpointsArray = specMap.get("endpoints")
    removeYArrayItem(endpointsArray, payload.endpointId)
  })
}

/**
 *
 *
 * FILTERS
 *
 *
 */
const addAppFilterWithSync = payload => dispatch => {
  dispatch(actions.addAppFilter(payload))

  postYjs(yDoc => {
    const specMap = yDoc.getMap("spec")
    const filtersArray = specMap.get("filters")
    addYMapToYArray(filtersArray, payload)
  })
}

const setAppFilterWithSync = payload => dispatch => {
  dispatch(actions.setAppFilter(payload))

  postYjs(yDoc => {
    const { filter, index } = payload
    const specMap = yDoc.getMap("spec")
    const filtersArray = specMap.get("filters")
    updateYArrayItem(filtersArray, filter, index)
  })
}

const moveAppFilterWithSync = payload => dispatch => {
  dispatch(actions.moveAppFilter(payload))

  postYjs(yDoc => {
    const { fromIndex, toIndex } = payload
    const specMap = yDoc.getMap("spec")
    const filtersArray = specMap.get("filters")
    moveYArrayItem(filtersArray, fromIndex, toIndex)
  })
}

const removeAppFilterWithSync = payload => dispatch => {
  dispatch(actions.removeAppFilter(payload))

  postYjs(yDoc => {
    const specMap = yDoc.getMap("spec")
    const filtersArray = specMap.get("filters")
    removeYArrayItem(filtersArray, payload.id)
  })
}

const allActions = {
  ...actions,
  saveLayout,
  saveLayouts,
  spawnNewWidget,
  closeWidgetEditor,
  copyNavWidget,
  copyPages,
  publishApp,
  loadAppSnapshot,
  pasteWidget,
  saveWidgetFromEditor,
  openWidgetEditor,
  triggerEndpoint,
  selectPoints,
  loadWidgetForm,
  appendSelectedPoints,
  selectWidgetWithSync,
  closeEditorWithSync,
  setAppThemeWithSync,
  addPageWithSync,
  removePageWithSync,
  renamePageWithSync,
  setWidgetWithSync,
  setWidgetOptionsWithSync,
  setWidgetLayoutWithSync,
  addWidgetWithSync,
  removeWidgetWithSync,
  addAppVariableWithSync,
  setAppVariableWithSync,
  setAppVariablesWithSync,
  moveAppVariablesWithSync,
  removeAppVariableWithSync,
  addAppEndpointWithSync,
  updateAppEndpointWithSync,
  removeAppEndpointWithSync,
  addAppFilterWithSync,
  setAppFilterWithSync,
  moveAppFilterWithSync,
  removeAppFilterWithSync,
  swapPageOrderWithSync,
}

export { allActions as actions }

appSlice.actions = allActions
export default appSlice
