import React, { useState, useCallback, useEffect, useRef } from "react"
import get from "lodash/get"
import styled from "styled-components"
import { toast } from "react-toastify"
import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { Panel, PanelGroup } from "react-resizable-panels"
import { Space, Menu, Table } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faProjectDiagram, faDatabase } from "@fortawesome/pro-solid-svg-icons"

import { storeToState } from "@dbai/tool-box"
import { PanelResizeHandle } from "@dbai/applet"
import {
  Form,
  PageSpinner,
  Select,
  AuthBoundary,
  ErrorMessage,
  useCurrentUser,
} from "@dbai/ui-staples"

import Graph from "./Graph"
import Header from "./Header"
import { useYjs } from "hooks"
import Notebook from "./Notebook"
import CodeSearch from "./CodeSearch"
import { GET_WORKFLOW } from "queries"
import { YjsProvider } from "./YjsContext"
import { selectIsLoading } from "selectors"
import { actions } from "reducers/notebookReducer"
import withCustomer from "components/shared/withCustomer"
import CommsChannelProvider from "components/shared/CommsContext"
import { KernelManager } from "components/pages/Workflows/Edit/shared/kernel"
import {
  usePythonResults,
  useDataframes,
  useDataframe,
} from "components/pages/Workflows/Edit/shared/util"

const Shape = styled.div`
  margin-top: 10px;
`

const StyledPanelGroup = styled(PanelGroup)`
  height: calc(100vh - 128px) !important;
`

const NotebookPanel = styled(Panel)`
  height: calc(100vh - 128px);
`

const RightPanel = styled(Panel)`
  height: calc(100vh - 128px);
`

// Only propagate saves when the workflow attribute changes.
const filterSave = (state, prev) => state.workflow === prev.workflow

const AutoSaveForm = ({ onStatus, filter, changedTime }) => {
  const { doc, provider } = useYjs()
  const dispatch = useDispatch()
  const pausedPath = "notebook.autosave.paused"
  const save = useCallback(() => {
    return dispatch(actions.saveWorkflow())
  }, [dispatch])

  const paused = useSelector(state => get(state, pausedPath))
  const lastChanged = useSelector(state => get(state, "notebook.lastChanged"))

  const handleSave = useCallback((state) => {
    // If we don't have Yjs here, or if we don't have a WS connection to
    // ko-op, save as before.
    if (!doc || !provider?.wsconnected) return save()

    /*
     * In Yjs, get<Type> on a doc is a "find or create" method so this should
     * never be null/falsey.
     */
    const metaData = doc.getMap("metadata")

    /*
     * Using null as the default, because new Date(undefined) means "now" and
     * new Date(null) means "Unix epoch start". We want to save if there is no
     * previous "lastSaved".
     */
    const lastSavedAt = new Date(metaData.get("lastSaved") || null).getTime()

    if (lastChanged && lastSavedAt < lastChanged) {
      /*
       * Mark when we _started_ our promise as we don't want to miss changes
       * that happen while this is in flight.
       */
      const attemptTime = new Date().getTime()
      return save().then(results => {
        metaData.set("lastSaved", attemptTime)
        return results
      })
    }

    return Promise.resolve()
  }, [doc, provider, save, lastChanged])

  return (
    <Form.AutoSave
      delay={5}
      max={15}
      onStatus={onStatus}
      filter={filter}
      save={handleSave}
      paused={paused}
    />
  )
}

const SpinnerWrapper = props => {
  const { children } = props
  const isLoading = useSelector(selectIsLoading)

  if (isLoading) return <PageSpinner />
  return <>{children}</>
}

const ColumnHistogramCell = props => {
  const { dfName, dfColumn } = props
  const [imageEncoding, setImageEncoding] = useState()
  const dataframeShapeCode = `
 hist_options = {
    'bins': 10,
    'color': 'steelblue',
    'linewidth': 1.2,
    'alpha': 0.7,
    'rwidth': 0.85,
    'figsize': (1, 0.7),
    'legend': False,
    'grid': False,
}\n
ax=${dfName}.hist(column="${dfColumn}", **hist_options)\n
ax[0, 0].axis('off')\n
ax[0, 0].set_title(None)\n
ax[0, 0].set_xlabel(None)\n
ax[0, 0].set_ylabel(None)\n
ax[0, 0].spines['top'].set_visible(False)\n
ax[0, 0].spines['right'].set_visible(False)\n
\n
`

  const dataframeShapeCallback = useCallback(results => {
    const responseText = results.data["image/png"]
    setImageEncoding(`data:image/jpeg;base64,${responseText}`)
  }, [])

  usePythonResults(dataframeShapeCode, dataframeShapeCallback)

  if (!imageEncoding) return
  return (
    <td {...props}>
      <img alt="" src={imageEncoding} />
    </td>
  )
}

const DataframeTableCell = props => {
  const { children, idx } = props
  if (idx === 0) return <ColumnHistogramCell {...props} />
  return <td {...props}> {children}</td>
}

const dataframeTableComponents = {
  body: {
    cell: DataframeTableCell,
  },
}

const Dataframes = () => {
  const [selectedDataframe, setSelectedDataframe] = useState()
  const { dataframesLoading, dataframes } = useDataframes()
  const { dataframeLoading, dataframe, shape } = useDataframe(selectedDataframe)
  const dataframeOptions = dataframes.map(df => ({ label: df, value: df }))
  const handleChange = value => {
    setSelectedDataframe(value)
  }

  return (
    <Space direction="vertical" style={{ padding: "16px", width: "100%" }}>
      <Select
        loading={dataframesLoading}
        options={dataframeOptions}
        onChange={handleChange}
      />
      <Shape>
        <b>Shape: </b> {shape}
      </Shape>
      <Table
        bordered
        size="small"
        loading={dataframeLoading}
        dataSource={dataframe.data}
        columns={dataframe.columns}
        scroll={{ y: `calc(100vh - 371px)`, x: "auto" }}
        components={dataframeTableComponents}
        pagination={{ hideOnSinglePage: true }}
      />
    </Space>
  )
}

const RightPanelTabs = props => {
  const items = [
    {
      label: "Graph",
      key: "graph",
      icon: <FontAwesomeIcon icon={faProjectDiagram} />,
    },
    {
      label: "Data Frames",
      key: "dataframes",
      icon: <FontAwesomeIcon icon={faDatabase} />,
    },
  ]

  const [current, setCurrent] = useState("graph")
  const onClick = e => {
    setCurrent(e.key)
  }

  const selectedTab =
    current === "graph" ? <Graph {...props} /> : <Dataframes />
  return (
    <>
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        items={items}
      />
      {selectedTab}
    </>
  )
}

export const EditWorkflow = props => {
  const [user] = useCurrentUser()
  const { customer } = props
  const [errorToast, setErrorToast] = useState(null)
  const [saveState, setSaveState] = useState("saved")
  const [changedTime, setChangedTime] = useState(null)
  const dispatch = useDispatch()
  const notebook = useSelector(state => state.notebook)
  const { id, cname } = useParams()
  const layout = useSelector(state => get(state?.notebook, "notebook.layout"))
  const notebookRef = useRef()
  const graphRef = useRef()

  const {
    error,
    loading,
    data,
    refetch: refetchWorkflow,
  } = useQuery(GET_WORKFLOW, {
    variables: { id, cname },
    fetchPolicy: "network-only",
  })
  const [spawnParams, setSpawnParams] = useState({
    workflowId: null,
    customerId: null,
  })

  useEffect(() => {
    if (data && Number(id) !== notebook.workflow.id) {
      const workflow = storeToState(data.customer.workflow)
      dispatch(actions.loadWorkflow(workflow))
    }
  }, [data, dispatch, id, notebook])

  useEffect(() => {
    if (data && data.customer) {
      setSpawnParams({
        workflowId: data.customer.workflow.id,
        customerId: data.customer.id,
        cname: data.customer.normalizedName,
      })
    }
  }, [data])

  useEffect(
    () => () => {
      dispatch(actions.reset())
    },
    [dispatch]
  )

  useEffect(() => {
    if (notebookRef.current && graphRef.current) {
      notebookRef.current.resize(layout.codeWidth)
      graphRef.current.resize(layout.graphWidth)
    }
  }, [layout.codeWidth, layout.graphWidth])

  const { workflow } = data?.customer || {}

  const nameForKernel = `workflow-${id}`

  const handleSaveStatus = useCallback(
    status => {
      if (status === "notSaved") {
        setChangedTime(new Date().getTime())
      }

      if (status === "errorSaving" && !toast.isActive(errorToast)) {
        setErrorToast(
          toast.error("Error Saving Workflow", { autoClose: false })
        )
      }

      if (status === "saved" && errorToast) {
        toast.dismiss(errorToast)
        setErrorToast(null)
      }

      setSaveState(status)
    },
    [errorToast]
  )
  if (loading || !notebook.workflow.id) return <PageSpinner />
  if (error) return <ErrorMessage error={error} />
  if (!data) return null

  return (
    <AuthBoundary subject="workflows" action="UPDATE">
      <YjsProvider
        id={id}
        cname={cname}
        user={user}
        customer={customer}
        workflow={workflow}
      >
        <SpinnerWrapper>
          <CodeSearch />
          <Form
            hideSubmit
            allowNested
            actions={actions}
            state={notebook}
            dispatch={dispatch}
            name="workflow-form"
          >
            {spawnParams.workflowId && (
              <KernelManager name={nameForKernel} spawnParams={spawnParams}>
                <CommsChannelProvider>
                  <Header
                    workflow={data.customer.workflow}
                    customer={customer}
                    saveState={saveState}
                    refetchWorkflow={refetchWorkflow}
                  />

                  <StyledPanelGroup direction="horizontal">
                    <NotebookPanel
                      defaultSize={layout.codeWidth}
                      collapsible
                      ref={notebookRef}
                    >
                      <Notebook {...props} />
                    </NotebookPanel>
                    <PanelResizeHandle />
                    <RightPanel
                      defaultSize={layout.graphWidth}
                      collapsible
                      ref={graphRef}
                    >
                      <RightPanelTabs />
                    </RightPanel>
                  </StyledPanelGroup>

                  <AutoSaveForm
                    onStatus={handleSaveStatus}
                    changedTime={changedTime}
                    filter={filterSave}
                  />
                </CommsChannelProvider>
              </KernelManager>
            )}
          </Form>
        </SpinnerWrapper>
      </YjsProvider>
    </AuthBoundary>
  )
}

export default withCustomer(EditWorkflow)
