import React, { useState, useContext, createContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

export const AccordionContext = createContext({})

const checkIfOpen = (open, id) => Boolean(open?.includes(id))

const addOrRemove = (open, sectionId) => {
  if (checkIfOpen(open, sectionId)) {
    return open.reduce((acc, next) => {
      if (next !== sectionId) return [...acc, next]
      return acc
    }, [])
  }

  return [...open, sectionId]
}

const StyledAccordion = styled.div`
  div.card {
    margin-bottom: 0px;
  }
`

const AccordionHeader = props => {
  const defaultButtonClass = "btn btn-link btn-block text-left"
  const { buttonClass = defaultButtonClass, handleClick, content } = props

  return (
    <div className="card-header">
      <button className={buttonClass} type="button" onClick={handleClick}>
        {content}
      </button>
    </div>
  )
}

export const AccordionContent = props => {
  const { children, headerContent, sectionId, Header } = props
  const { open, toggleOpen } = useContext(AccordionContext)
  const handleClick = () => toggleOpen(sectionId)
  const isOpen = checkIfOpen(open, sectionId)
  const collapseClass = isOpen ? "collapse show" : "collapse"
  const HeaderComponent = Header || AccordionHeader

  return (
    <div className="card">
      <HeaderComponent
        content={headerContent}
        sectionId={sectionId}
        handleClick={handleClick}
      />
      <div className={collapseClass}>
        <div className="cardBody">{children}</div>
      </div>
    </div>
  )
}

AccordionContent.propTypes = {
  sectionId: PropTypes.string.isRequired,
  headerContent: (props, propName, componentName) => {
    const propType = typeof props[propName]
    const isObject = propType === "object"

    if (props.Header && !isObject) {
      return new Error(
        `Invalid Prop ${propName} supplied to ${componentName}. ` +
          `If Header is supplied ${propName} should be an object`
      )
    }

    if (
      !props.Header &&
      !React.isValidElement(props[propName]) &&
      typeof props[propName] !== "string"
    ) {
      return new Error(
        `Invalid Prop ${propName} supplied to ${componentName}. ` +
          `If Header is not supplied ${propName} should be a string or react element`
      )
    }
  },
  Header: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
}

/*
 * This component expects that it will recieve AccordionContent components as
 * children. An example usage might look like the following:
 * <Accordion>
 *   <AccordionContent
 *     headerContent="Click To Open!"
 *     sectionId="someIdentifier"
 *   >
 *     <div>What renders when not collapsed...</div>
 *   </AccordionContent>
 * </Accordion>
 */
const Accordion = (props) => {
  const { defaultOpenSections = []} = props
  const [open, setOpen] = useState(defaultOpenSections)
  const toggleOpen = sectionId => {
    const newOpen = addOrRemove(open, sectionId)
    setOpen(newOpen)
  }

  return (
    <AccordionContext.Provider value={{ toggleOpen, open }}>
      <StyledAccordion className="accordion">{props.children}</StyledAccordion>
    </AccordionContext.Provider>
  )
}

Accordion.Content = AccordionContent
Accordion.Context = AccordionContext
export default Accordion
