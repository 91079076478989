import CellResults from "../../Notebook/Cell/CellResults"
import styled from "styled-components"

const PresentationCell = styled.div`
  margin-top: 42px;
`

const WorkflowPresentationWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const InnerContent = styled.div`
  width: fit-content;
`

const WorkflowPresentation = nodes => {
  const cells = nodes.nodes.map(node => node.cells).flat()
  const cellResults = cells.map(cell => {
    return (
      <PresentationCell>
        <CellResults cell={cell} hideControls={true} />
      </PresentationCell>
    )
  })

  return (
    <WorkflowPresentationWrapper>
      <InnerContent>{cellResults}</InnerContent>
    </WorkflowPresentationWrapper>
  )
}

export default WorkflowPresentation
