import React, { memo, useMemo, useCallback } from "react"
import styled from "styled-components"
import { Handle, Position } from "reactflow"
import { Menu } from "antd"
import { useCurrentCustomer, FAMemo } from "@dbai/ui-staples"
import { storeToState } from "@dbai/tool-box"
import { useQuery } from "@apollo/client"

import { nodeIconMap } from "components/pages/Workflows/shared/IconPicker"
import { GET_COMPONENTS, GET_PUBLISHED_COMPONENTS } from "queries"
import { actions as notebookActions } from "reducers/notebookReducer"
import { useDispatch } from "react-redux"

const StyledEmpty = styled.div`
  visibility: visible;
  transition: all 0.3s ease;
  border-radius: 20px;
  height: 200px;
  width: 200px;
  padding-top: 10px;
  background-color: ${props => props.theme.emptyNodeBg};
  box-shadow: ${props =>
    props.selected ? "0px 0px 10px 10px rgba(255,255,255,0.3)" : "none"};
`

const StyledHandle = styled(Handle)`
  background-color: ${props => props.theme.colorBgBase};
  border-color: ${props =>
    props.selected ? props.theme.nodeBorderSelected : props.theme.nodeBorder};
  height: 10px;
  width: 20px;
  border-radius: 5px;
`

const StyledMenu = styled(Menu)`
  && .ant-menu-sub {
    max-height: 500px !important;
    overflow-y: auto !important;
  }
`

const StyledLabel = styled.label`
  font: 20px "Industry";
  color: ${props => props.theme.selectedNodeText};
  margin-left: 5px;
`

const StyledListLabel = styled.label`
  font: 15px "Industry";
  color: ${props => props.theme.selectedNodeText};
  margin-left: 5px;
  position: relative;
  top: -5px;
`

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${props => props.theme.nodeBorder};
`

const EmptyNode = ({ id, data, isConnectable, focused, selected }) => {
  const dispatch = useDispatch()
  const [customer] = useCurrentCustomer()
  const variables = { cname: customer.normalizedName }

  const { data: queryData } = useQuery(GET_COMPONENTS, {
    variables,
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  })

  const { data: publicQueryData } = useQuery(GET_PUBLISHED_COMPONENTS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  })

  const mapActions = useCallback((data, accessor) => {
    if (!data) return []
    return (accessor(data) || [])
      .filter(item => item.currentRevision || item.component)
      .map(item => ({
        label: item.name || item.component.name,
        value: storeToState({
          name: item.name || item.component.name,
          ...(item.currentRevision || item.component.currentRevision || {}),
        }),
      }))
  }, [])

  const convertActions = useCallback(
    actions =>
      actions.map(action => ({
        key: action.label,
        label: <StyledListLabel>{action.label}</StyledListLabel>,
        data: action.value,
        icon: (
          <FAMemo
            icon={nodeIconMap[action.value.spec.icon]}
            style={{
              backgroundColor: action.value.spec.color,
              padding: 5,
              color: "white",
              borderRadius: 5,
              marginTop: 8,
            }}
            fixedWidth
          />
        ),
      })),
    []
  )

  const components = mapActions(queryData, data => data.customer?.components)
  const publicComponents = mapActions(publicQueryData, data =>
    data.componentPublications?.map(cp => cp.component)
  )

  const componentItems = convertActions(components)
  const publicComponentItems = convertActions(publicComponents)

  const patchNode = useCallback(
    item => {
      const value =
        item.key === "newPythonScript"
          ? {
              spec: {
                type: "script",
                color: "#0c2963",
                artifacts: [],
                cells: [],
              },
            }
          : components.concat(publicComponents).find(a => a.label === item.key)
              ?.value

      dispatch(
        notebookActions.cloneIntoCurrentNodeFromEmptyNode(
          id,
          value,
          value.spec.type
        )
      )
    },
    [dispatch, components, publicComponents, id]
  )

  const items = useMemo(
    () => [
      { key: "newPythonScript", label: "New Python Script" },
      {
        key: "publicComponentsPicker",
        label: "Public Component",
        children: publicComponentItems,
      },
      {
        key: "componentsPicker",
        label: "Components",
        children: componentItems,
      },
    ],
    [publicComponentItems, componentItems]
  )

  return (
    <StyledEmpty selected={selected}>
      <StyledHandle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
        selected={focused || selected}
      />
      <StyledLabel>SELECT NODE TYPE</StyledLabel>
      <Divider />
      <StyledMenu
        items={items}
        getPopupContainer={node => node.parentNode}
        className="nowheel"
        onSelect={patchNode}
      />
      <StyledHandle
        type="source"
        position={Position.Bottom}
        isConnectable={isConnectable}
        selected={focused || selected}
      />
    </StyledEmpty>
  )
}

export default memo(EmptyNode)
