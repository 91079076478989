import React, { useMemo, useState, useEffect, useRef } from "react"
import { Tooltip, Button, Row, Col, Space } from "antd"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"
import { faSave } from "@fortawesome/pro-regular-svg-icons"
import { faStop, faRedo, faMicrochip } from "@fortawesome/pro-solid-svg-icons"

import { FAMemo } from "@dbai/ui-staples"
import { toast } from "react-toastify"

import UserIcons from "./UserIcons"
import { useCommsChannels } from "hooks"
import WorkflowMenu from "./WorkflowMenu"
import { actions } from "reducers/notebookReducer"
import RunWorkflowButton from "./RunWorkflowButton"
import PageHeader from "components/shared/PageHeader"
import { selectWorkflow } from "selectors"
import { onlyType } from "components/pages/Workflows/Edit/shared/kernel"
import { useKernel } from "components/pages/Workflows/Edit/shared/kernel"

const PageHeaderContainer = styled.div`
  .ant-btn {
    height: 1.6rem;
    padding: 0.25rem 0.5rem;
    line-height: 1rem;
  }
`

const Indicator = styled.span`
  display: inline-flex;
  padding: 0.25rem 0.5rem;
  margin: 0 3px;
  :first-child {
    margin-left: 0;
  }
  :last-child {
    margin-right: 0;
  }

  color: #bebebe;
  background-color: inherit;
  border: 1px solid;
  height: 1.6rem;
  line-height: 1rem;
  border-radius: 6px;
`

const IndicatorTooltip = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  div {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
  }
`

const mapSaveState = {
  notSaved: "border-warning text-warning",
  saving: "border-warning text-warning",
  saved: "border-success text-success",
  errorSaving: "border-danger text-danger",
}

const mapKernelState = {
  disconnected: "border-danger text-danger",
  connecting: "border-warning text-warning",
  busy: "border-success text-success",
  idle: "border-secondary text-secondary",
  error: "border-danger  text-danger",
}

const mapStateToDisplay = {
  disconnected: "Disconnected",
  connecting: "Connecting...",
  busy: "Busy",
  idle: "Idle",
  error: "Error",
  notSaved: "Not Saved",
  saving: "Saving...",
  saved: "Saved",
  errorSaving: "Error Saving",
}

const CustomTip = React.memo(({ title, content }) => {
  return (
    <IndicatorTooltip>
      <div>{title}</div>
      <div>{content}</div>
    </IndicatorTooltip>
  )
})

const Header = props => {
  const { customer, saveState } = props
  const workflow = useSelector(selectWorkflow)
  const { error, loading, kernel } = useKernel()
  const [kernelState, setKernelState] = useState("disconnected")
  const dispatch = useDispatch()
  const { rehydrateInputs, restartChannels } = useCommsChannels()
  const toastId = useRef(null)
  const { normalizedName: cname } = customer

  useEffect(() => {
    const cleanup = () => {
      if (toastId.current) toast.dismiss(toastId.current)
    }
    if (error) {
      toast.error("Error connecting to the kernel. Please refresh the page.")
      return setKernelState("error")
    }
    if (loading) {
      toastId.current = toast.info("Connecting to the kernel...", {
        autoClose: false,
      })
      setKernelState("connecting")
      return cleanup
    }
    if (!kernel) return cleanup
    const [iopub] = kernel.channel("iopub")
    setKernelState("idle")
    if (toastId.current) toast.dismiss(toastId.current)

    iopub.filter(onlyType("status")).subscribe(data => {
      const { execution_state } = data?.content || {}
      if (execution_state === "restarting") {
        dispatch(actions.handleKernelRestart())
      }
      setKernelState(execution_state)
    })
    return cleanup
  }, [error, loading, kernel, dispatch, toastId])

  const interruptKernel = () => kernel && kernel.interrupt()
  const restartKernel = () => {
    if (kernel) {
      kernel.restart().then(restartChannels).then(rehydrateInputs)
    }
  }

  const kernelTip = mapStateToDisplay[kernelState]
  const saveTip = mapStateToDisplay[saveState]

  const routes = useMemo(
    () => [
      { breadcrumbName: customer.name },
      { breadcrumbName: "Workflows", url: `/${cname}/workflows/` },
      {
        value: workflow.name,
        onChange: value => dispatch(actions.setName({ value })),
      },
    ],
    [cname, customer.name, dispatch, workflow.name]
  )

  return (
    <PageHeaderContainer id="workflow-header">
      <PageHeader routes={routes}>
        <Row gutter={[16]} align="middle">
          <Col>
            <UserIcons />
          </Col>
          <Col>
            <Space>
              <Tooltip
                placement="bottom"
                title={<CustomTip title="Kernel Status" content={kernelTip} />}
              >
                <Indicator className={mapKernelState[kernelState]}>
                  <FAMemo icon={faMicrochip} />
                </Indicator>
              </Tooltip>
              <Tooltip
                placement="bottom"
                title={<CustomTip title="Workflow State" content={saveTip} />}
              >
                <Indicator className={mapSaveState[saveState]}>
                  <FAMemo icon={faSave} />
                </Indicator>
              </Tooltip>
            </Space>
          </Col>
          <Col>
            <Button.Group>
              <Tooltip title="Interrupt Kernel">
                <Button
                  type="default"
                  onClick={interruptKernel}
                  icon={<FAMemo icon={faStop} />}
                />
              </Tooltip>
              <Tooltip title="Restart Kernel">
                <Button
                  type="default"
                  onClick={restartKernel}
                  icon={<FAMemo icon={faRedo} />}
                />
              </Tooltip>
              <RunWorkflowButton />
            </Button.Group>
          </Col>
          <Col>
            <WorkflowMenu
              customerId={customer.id}
              workflowId={workflow.id}
              normalizedName={customer.normalizedName}
              workflowName={workflow.name}
            />
          </Col>
        </Row>
      </PageHeader>
    </PageHeaderContainer>
  )
}

export default React.memo(Header)
