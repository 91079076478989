const indexNodes = (pre, node) => ({ [node.id]: node, ...pre })

const createAdjacencyList = (nodes, edges) => {
  const adjList = {}
  const inDegree = {}
  nodes.forEach(node => {
    adjList[node.id] = []
    inDegree[node.id] = 0
  })

  edges.forEach(edge => {
    adjList[edge.source].push(edge.target)
    inDegree[edge.target] += 1
  })

  return { adjList, inDegree }
}

const followBranchWithDependencies = (
  nodeId,
  adjList,
  visited,
  branch,
  indexedNodes,
  resolved
) => {
  if (resolved.has(nodeId)) return

  visited.add(nodeId)

  Object.keys(adjList).forEach(sourceId => {
    if (adjList[sourceId].includes(nodeId) && !resolved.has(sourceId)) {
      followBranchWithDependencies(
        sourceId,
        adjList,
        visited,
        branch,
        indexedNodes,
        resolved
      )
    }
  })

  branch.push(nodeId)
  resolved.add(nodeId)

  const sortedChildren = adjList[nodeId]
    .map(neighborId => indexedNodes[neighborId])
    .sort((a, b) => a.position.x - b.position.x)
    .map(node => node.id)

  sortedChildren.forEach(neighborId => {
    if (!visited.has(neighborId)) {
      followBranchWithDependencies(
        neighborId,
        adjList,
        visited,
        branch,
        indexedNodes,
        resolved
      )
    }
  })
}

const nodeSort = workflow => {
  const { nodes, edges } = workflow.spec

  if (!Array.isArray(nodes) || !Array.isArray(edges)) {
    throw new TypeError("Nodes and edges must be arrays")
  }

  const indexedNodes = nodes.reduce(indexNodes, {})
  if (Object.keys(indexedNodes).length < 1) return []
  if (edges.length < 1) return nodes.sort((a, b) => a.position.x - b.position.x)

  const { adjList, inDegree } = createAdjacencyList(nodes, edges)

  const zeroInDegreeNodes = Object.keys(inDegree).filter(
    nodeId => inDegree[nodeId] === 0
  )

  const visited = new Set()
  const resolved = new Set()
  const branches = []

  zeroInDegreeNodes
    .sort((a, b) => indexedNodes[a].position.x - indexedNodes[b].position.x)
    .forEach(nodeId => {
      if (!visited.has(nodeId)) {
        const branch = []
        followBranchWithDependencies(
          nodeId,
          adjList,
          visited,
          branch,
          indexedNodes,
          resolved
        )
        branches.push(branch)
      }
    })

  const sortedNodes = branches.flat()

  const seenNodeIds = new Set()
  const uniqueSortedNodes = []
  sortedNodes.forEach(nodeId => {
    if (!seenNodeIds.has(nodeId)) {
      seenNodeIds.add(nodeId)
      uniqueSortedNodes.push(indexedNodes[nodeId])
    }
  })

  const connectedNodeIds = new Set(
    edges.flatMap(edge => [edge.source, edge.target])
  )
  const unconnectedNodes = Object.keys(indexedNodes)
    .map(k => indexedNodes[k])
    .filter(node => !connectedNodeIds.has(node.id))

  const filteredSortedNodes = uniqueSortedNodes.filter(node =>
    connectedNodeIds.has(node.id)
  )

  return [...filteredSortedNodes, ...unconnectedNodes]
}

export default nodeSort
