import React, { memo, useCallback, useRef, useMemo } from "react"
import { Button } from "antd"

import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import {
  faChevronDown,
  faChevronUp,
  faPlay,
  faArrowUpRightAndArrowDownLeftFromCenter,
} from "@fortawesome/pro-solid-svg-icons"
import { Handle, Position, NodeResizeControl } from "reactflow"
import { get } from "lodash"

import { actions } from "reducers/notebookReducer"
import { selectNodes, selectNodeStatus, selectFocused } from "selectors"
import ComponentForm from "components/pages/Workflows/Edit/Notebook/Cell/ComponentForm"
import { FAMemo } from "@dbai/ui-staples"
import NodeIcon from "./NodeIcon"
import { StyledNode, Divider, NodeTitle, FormContainer } from "./StyledNode"
import { useExecCell } from "components/pages/Workflows/Edit/shared/util"

const NodeExpanderContainer = styled.div`
  float: bottom;
  right: 8px;
  position: fixed;
  padding-top: 13px;
  padding-right: 8px;
  padding-left: 20px;
  margin-left: 20px;
`
const StyledButton = styled(Button)`
  float: right;
  margin-top: 10px;
  margin-right: 40px;
`
const NodeExpander = props => {
  const downColor = "grey"
  const dropIcon = props.dropped ? faChevronUp : faChevronDown
  return (
    <FAMemo
      icon={dropIcon}
      size="xl"
      style={{ color: downColor, cursor: "pointer" }}
      {...props}
    />
  )
}

const controlStyle = {
  background: "transparent",
  border: "none",
}

const ComponentNode = ({ data, selected, id, isConnectable, nodeIdx }) => {
  const nodeStatus = useSelector(state =>
    selectNodeStatus(state, { nodeId: id })
  )
  const dispatch = useDispatch()
  const execCell = useExecCell()
  const previousSelected = useRef(selected)
  const { node: focusedNode } = useSelector(selectFocused)
  const focused = useMemo(() => {
    return focusedNode === id
  }, [focusedNode, id])
  const focusCell = useCallback(() => {
    // transition from not selected to selected
    if (selected && !previousSelected.current) {
      previousSelected.current = true
      dispatch(
        actions.focusCell({
          cellIdx: 0,
          node: id,
        })
      )
      return
    }

    // transition from selected to not selected
    if (!selected && previousSelected.current) {
      previousSelected.current = false
      dispatch(actions.clearFocus())
    }

    // transition from selected and not focused to selected and focused
    if (selected && previousSelected.current && !focused) {
      previousSelected.current = true
      dispatch(
        actions.focusCell({
          cellIdx: 0,
          node: id,
        })
      )
    }
  }, [dispatch, selected, focused, id])

  const specNodes = useSelector(selectNodes)
  const node = specNodes.find(node => node.id === id)
  const dropped = node?.dropped || false

  const handleDrop = useCallback(() => {
    dispatch(actions.nodeDropped({ id }))
  }, [dispatch, id])

  const handleResizeEnd = useCallback(
    (event, resizeParameters) => {
      dispatch(
        actions.resizeNode({
          id,
          resizeParameters,
        })
      )
    },
    [dispatch, id]
  )

  const runNode = useCallback(() => {
    node.cells.forEach((cell, idx) => {
      execCell({
        docValue: cell.source.join(""),
        path: node.cells,
        cellId: cell.uuid,
        cellType: cell.cellType,
        handleAutosave: false,
        nodeId: node.id,
      })
    })
  }, [node, execCell])

  const indexes = specNodes.reduce(
    (pre, n, idx) => ({ [n.id]: idx, ...pre }),
    {}
  )

  if (!node) return null
  return (
    <>
      <StyledNode
        selected={selected}
        focused={focused}
        status={nodeStatus}
        onClick={focusCell}
        dropped={dropped}
        width={node.style?.width || node.width}
        height={node.style?.height || node.height}
        color={data.color}
        className="nowheel"
        nodeType="component"
      >
        <Handle
          type="target"
          position={Position.Top}
          isConnectable={isConnectable}
          selected={focused || selected}
        />
        <NodeTitle>
          <NodeIcon
            id={id}
            color={data.color}
            status={nodeStatus}
            type={data.type}
            isAction={Boolean(data.actionId)}
            selected={selected}
          />
        </NodeTitle>
        <NodeExpanderContainer onClick={handleDrop}>
          <NodeExpander dropped={dropped} />
        </NodeExpanderContainer>
        <label color={data.color}>{data.label}</label>
        <StyledButton
          type="text"
          onClick={runNode}
          icon={<FAMemo icon={faPlay} />}
        />
        <Divider focused={focused} selected={selected} status={nodeStatus} />
        {dropped ? (
          <FormContainer className="nowheel">
            <div>
              <ComponentForm
                node={node}
                nodeIdx={indexes[node.id]}
                selected={selected}
                componentPath={`/components/${get(node, "metadata.componentId", "")}`}
              />
            </div>
          </FormContainer>
        ) : null}
        <Handle
          type="source"
          position={Position.Bottom}
          isConnectable={isConnectable}
          selected={focused || selected}
        />
      </StyledNode>
      {dropped ? (
        <NodeResizeControl
          style={controlStyle}
          onResizeStart={handleResizeEnd}
          onResizeEnd={handleResizeEnd}
        >
          <FAMemo icon={faArrowUpRightAndArrowDownLeftFromCenter} />
        </NodeResizeControl>
      ) : null}
    </>
  )
}

export default memo(ComponentNode)
