import React, { useMemo, useCallback } from "react"
import CodeMirror from "codemirror"
import get from "lodash/get"
import { useSelector, useDispatch } from "react-redux"

import Cell from "./Cell"
import { actions } from "reducers/notebookReducer"
import { checkIfLastCell } from "components/pages/Workflows/Edit/shared/util"

const goCellUp = focusPrev => doc => {
  const { line, ch } = doc.getCursor()
  if (line + ch !== 0) return CodeMirror.commands.goLineUp(doc)
  focusPrev()
}

const goCellDown = focusNext => doc => {
  const lastLine = doc.getLine(doc.lastLine())
  const lastCh = lastLine.length

  const { line, ch } = doc.getCursor()
  if (line === doc.lastLine() && ch === lastCh) {
    focusNext()
    return
  }
  CodeMirror.commands.goLineDown(doc)
}

const ScriptCells = React.memo(props => {
  const {
    node,
    path,
    prefix,
    nodeIdx,
    focusNode,
    showFlow = true,
    showResults = true,
  } = props

  const nodeId = node.id
  const dispatch = useDispatch()
  const { focused, mode, layout } = useSelector(state =>
    get(state?.notebook, "notebook")
  )
  const { cells } = node

  const focusPrev = useCallback(() => {
    if (focused.node === nodeId) dispatch(actions.focusUp())
  }, [dispatch, focused.node, nodeId])

  const focusNext = useCallback(() => {
    if (focused.node === nodeId) dispatch(actions.focusDown())
  }, [dispatch, focused.node, nodeId])

  const extraKeys = useMemo(() => {
    return {
      Up: goCellUp(focusPrev),
      Down: goCellDown(focusNext),
      Esc: () => {},
    }
  }, [focusNext, focusPrev])

  const onExec = useCallback(
    doc => {
      if (checkIfLastCell()) {
        dispatch(actions.appendCell({ nodeIdx }))
      }
      focusNext()
    },
    [dispatch, focusNext, nodeIdx]
  )

  return cells.map((cell, idx) => {
    const cellPrefix = attr => prefix(`cells[${idx}].${attr}`)
    const isCellFocused = focused.node === nodeId && focused.cellIdx === idx

    return (
      <Cell
        key={cell.uuid}
        cell={cell}
        doc={layout.doc}
        nodeId={nodeId}
        nodeIdx={nodeIdx}
        mode={mode}
        nodeCollapsed={node.collapsed}
        isFocused={isCellFocused}
        focusNode={focusNode}
        cellIdx={idx}
        onExec={onExec}
        prefix={cellPrefix}
        path={`${path}.cells[${idx}]`}
        extraKeys={extraKeys}
        showFlow={showFlow}
        showResults={showResults}
        node={node}
      />
    )
  })
})

export default ScriptCells
