import React, { memo, useCallback, useMemo, useEffect, useRef } from "react"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { Card, Row, Col, Input, Form } from "antd"
import styled from "styled-components"
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism"

import { useCurrentCustomer } from "@dbai/ui-staples"
import { SchemaForm } from "@dbai/applet"

import config from "config"
import { useComponentJSONSchema } from "hooks"
import CellResults from "./CellResults"
import DelayedRender from "./DelayedRender"
import { actions } from "reducers/notebookReducer"
import { useDataframes } from "components/pages/Workflows/Edit/shared/util"

const StyledForm = styled(Row)`
  width: 100%;
`
const ContainerStyled = styled(Row)`
  padding: 20px;
`
const FormWrappedStyled = styled(Row)`
  margin: 0px !important;
`

const escapeLineBreaks = str => str.replace(/\n/g, "\\n")

const parseInputs = (data, schema) => {
  const inputName = schema.title
  if (data === undefined || data === null) {
    return ""
  }
  let code =
    "{\n" +
    Object.keys(data)
      .map(item => {
        let value = data[item]
        const schemaProp = schema.properties[item]
        if (schemaProp.type === "string") {
          value = `"${escapeLineBreaks(data[item])}"`
        }
        if (schemaProp.type === "boolean") {
          value = value ? "True" : "False"
        }
        return `  "${item}": ${[null, undefined].includes(value) ? "None" : value},`
      })
      .join("\n") +
    "\n}"

  const inputLine = `${inputName}(**${code})`
  return "input = " + inputLine
}

const DividerForOutput = styled.div`
  width: 100%;
  height: 2px;
  float: right;
  background-color: ${props =>
    (props.theme.themeMode === "dark" &&
      (props.selected
        ? "rgba(255, 255, 255, 1)"
        : "rgba(255, 255, 255, 0.4)")) ||
    (props.theme.themeMode === "light" &&
      (props.selected ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.4)"))};
  margin-bottom: 5px;
  margin-top: 15px;
`

const OutputContainer = styled(Card)`
  display: flex;
  flex-direction: row;
  width: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
`

const transformComponentSchema = (schema, dataframes, getDataframes) => {
  const { properties } = schema
  if (!properties) return schema
  return {
    ...schema,
    properties: Object.keys(properties).reduce((acc, key) => {
      const { metadata } = properties[key]

      // if the component is set to DataframeSelect, replace it with LocalSelect and inject dataframe options
      if (metadata?.component === "DataframeSelect") {
        return {
          ...acc,
          [key]: {
            ...properties[key],
            type: "string",
            metadata: {
              ...metadata,
              component: "LocalSelect",
              options: dataframes.map(df => ({ label: df, value: df })),
              onDropdownVisibleChange: open => {
                if (open) {
                  getDataframes()
                }
              },
            },
          },
        }
      }
      return {
        ...acc,
        [key]: properties[key],
      }
    }, {}),
  }
}

const ComponentForm = ({ nodeIdx, node }) => {
  const showOutput = node.cells[2].metadata.showOutput
  const componentCode = node.cells[0].source.join("\n")
  const dispatch = useDispatch()
  const { cname } = useParams()
  const {
    inputSchema,
    outputSchema,
    error: schemaError,
    functionName,
  } = useComponentJSONSchema(componentCode)
  const [{ id: customerId }] = useCurrentCustomer()
  const { dataframes, getDataframes } = useDataframes()
  const prevFunctionNameRef = useRef()
  const prevInputSchemaRef = useRef()

  useEffect(() => {
    if (!node.cells[2].source[0]) {
      const outputCellCode = outputSchema
        ? `${node.metadata.componentOutputName} = ${functionName}(input)`
        : `${functionName}(input)`
      dispatch(
        actions.setAndSyncCell({
          node,
          nodeIdx,
          cellIdx: 2,
          sourceValue: outputCellCode,
          metadata: {
            showCode: false,
          },
        })
      )
    }
  }, [outputSchema, functionName, node, nodeIdx, dispatch])

  const handleFormChange = useCallback(
    data => {
      dispatch(
        actions.updateNodeField({
          nodeIdx,
          field: "metadata.componentInput",
          value: data,
        })
      )
      if (!inputSchema?.title) return

      const cellCode = parseInputs(data, inputSchema)
      dispatch(
        actions.setAndSyncCell({
          node,
          nodeIdx,
          cellIdx: 1,
          sourceValue: cellCode,
          metadata: {
            showCode: false,
          },
        })
      )
    },
    [node, inputSchema, dispatch, nodeIdx]
  )

  const handleOutputNameChange = useCallback(
    data => {
      const newSourceValue = `${data.target.value} = ${functionName}(input)`
      if (node.cells[2].source[0] !== newSourceValue) {
        dispatch(
          actions.updateNodeField({
            nodeIdx,
            field: "metadata.componentOutputName",
            value: data.target.value,
          })
        )

        dispatch(
          actions.setAndSyncCell({
            node,
            nodeIdx,
            cellIdx: 2,
            sourceValue: newSourceValue,
            metadata: {
              showCode: false,
            },
          })
        )
      }
    },
    [nodeIdx, dispatch, node, functionName]
  )

  useEffect(() => {
    const newSourceValue = `${node.metadata.componentOutputName} = ${functionName}(input)`
    if (
      functionName !== prevFunctionNameRef.current &&
      node.cells[2].source[0] !== newSourceValue
    ) {
      const newSourceValue = outputSchema
        ? `${node.metadata.componentOutputName} = ${functionName}(input)`
        : `${functionName}(input)`
      dispatch(
        actions.setAndSyncCell({
          node,
          nodeIdx,
          cellIdx: 2,
          sourceValue: newSourceValue,
          metadata: {
            showCode: false,
          },
        })
      )
      prevFunctionNameRef.current = functionName
    }
  }, [functionName, node, nodeIdx, dispatch, outputSchema])

  const transformedSchema = useMemo(() => {
    return transformComponentSchema(inputSchema, dataframes, getDataframes)
  }, [inputSchema, dataframes, getDataframes])

  const componentCodeCell = node.cells[0]
  const cell = node.cells[2]
  const outputName = node.metadata.componentOutputName

  return (
    <ContainerStyled>
      <Col>
        <FormWrappedStyled gutter={[16, 16]}>
          <StyledForm>
            {inputSchema?.properties ? (
              <SchemaForm
                cname={cname}
                value={node.metadata.componentInput || {}}
                schema={transformedSchema}
                hideSave
                appConfig={config}
                customerId={customerId}
                onFormChange={handleFormChange}
              />
            ) : null}
          </StyledForm>
        </FormWrappedStyled>
        {schemaError ? (
          <Card className="nodrag">
            <SyntaxHighlighter language="python" style={coy}>
              {schemaError}
            </SyntaxHighlighter>
            <DelayedRender>
              <CellResults cell={componentCodeCell} />
            </DelayedRender>
          </Card>
        ) : null}
      </Col>
      <DividerForOutput />
      <OutputContainer showOutput={showOutput}>
        <DelayedRender>
          <CellResults cell={cell} />
        </DelayedRender>
      </OutputContainer>
      {!!outputSchema && (
        <Col span={24}>
          <Form.Item label="Output Name">
            <Input
              style={{ width: 300 }}
              value={outputName}
              onChange={handleOutputNameChange}
            />
          </Form.Item>
        </Col>
      )}
    </ContainerStyled>
  )
}
export default memo(ComponentForm)
