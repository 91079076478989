import { useCallback, useEffect, useState, useMemo } from "react"
import { useCurrentUser } from "@dbai/ui-staples"
import { useSessionId } from "hooks"
import { useKernel } from "components/pages/Workflows/Edit/shared/kernel"
import { execPythonCode } from "components/pages/Workflows/Edit/shared/util"

const allowedMethods = ["get", "post", "put", "delete", "patch"]

// NOTE: this will only work if the python code uses a `display` function. Using `print` goes through iopub stream
export const usePythonResults = (code, callback) => {
  const { error: kernelError, loading: kernelLoading, kernel } = useKernel()
  const [user] = useCurrentUser()
  const sessionId = useSessionId()

  useEffect(() => {
    if (kernelLoading || kernelError) {
      return
    }
    const sub = execPythonCode(kernel, sessionId, user, code, callback)
    return () => sub.unsubscribe()
  }, [kernel, kernelError, kernelLoading, user, sessionId, code, callback])
}

export const getComponentFunctionName = componentCode => {
  if (!componentCode) return
  const dbaiIndex = componentCode
    .split("\n")
    .findIndex(line => line.includes("@dbai_component"))
  let functionName
  if (dbaiIndex !== -1) {
    const defLine = componentCode.split("\n")[dbaiIndex + 1]
    const match = defLine.match(/def (\w+)\(/)
    if (match && match.length > 1) {
      functionName = match[1]
    }
  }
  return functionName
}

const removeCharAtPosition = (str, position) => {
  // Ensure the position is valid
  if (position < 0 || position >= str.length) {
    throw new Error("Invalid position");
  }

  // Use slice to get the parts of the string before and after the character to remove
  const before = str.slice(0, position);
  const after = str.slice(position + 1);

  // Concatenate the parts
  return before + after;
}

// Regex to match single backslashes but exclude the second backslash in double backslashes
const excessBackslashRegex = /\\(?!\\)/g;

export const useComponentJSONSchema = componentCode => {
  const [functionName, setFunctionName] = useState("")
  const [spec, setSpec] = useState({})
  const [error, setError] = useState(false)
  const [method, setMethod] = useState("GET")
  const [parameters, setParameters] = useState([])
  const [requestBody, setRequestBody] = useState({})
  const [responses, setResponses] = useState({})
  const [inputSchema, setInputSchema] = useState({})
  const [outputSchema, setOutputSchema] = useState(null)

  const componentCodeWithSchema = useMemo(() => {
    return `
from dbai.components import parse_component_code

component_code = '''
${componentCode}
'''

parse_component_code(component_code).model_dump_json()
    `
  }, [componentCode])

  const getSchemaName = useCallback(body => {
    if (
      !body ||
      !body.content ||
      !body.content["application/json"] ||
      !body.content["application/json"].schema ||
      !body.content["application/json"].schema["$ref"]
    ) {
      return null
    }
    const schemaPath = body.content["application/json"].schema["$ref"]
    const schemaName = schemaPath.split("/")
    return schemaName.pop()
  }, [])

  const getSchemaFromResponse = useCallback(body => {
    if (
      !body ||
      !body.content ||
      !body.content["application/json"] ||
      !body.content["application/json"].schema ||
      !body.content["application/json"].schema["type"]
    ) {
      return null
    }
    return body.content["application/json"].schema
  }, [])

  const componentCodeCallback = useCallback(
    results => {
      if (!results || !results.data || !results.data["text/plain"]) {
        console.log("No valid results")
        return
      }
      const responseText = results.data["text/plain"]
      try {
        const cleanedResponse = responseText.slice(1, -1)
        const furtherCleanedResponse = [...cleanedResponse.matchAll(excessBackslashRegex)].reduce((acc, match) => {

          return {
            pattern: removeCharAtPosition(acc.pattern, match.index - acc.count),
            count: acc.count + 1,
          }
        }, { pattern: cleanedResponse, count: 0})

        const res = JSON.parse(furtherCleanedResponse.pattern)
        const functionName = res.function_name
        const spec = res.json_schema
        setFunctionName(functionName)
        setSpec(spec)
        if (res.error) {
          setError(res.error.trace)
        } else {
          setError(null)
        }
        const pathItem = spec?.paths[`/${functionName}`]
        allowedMethods.forEach(method => {
          if (pathItem?.[method]) {
            setMethod(method)
            setParameters(pathItem[method].parameters)
            setRequestBody(pathItem[method].requestBody)
            setResponses(pathItem[method].responses)
            const schemaName = getSchemaName(pathItem[method].requestBody)
            if (spec.components.schemas[schemaName]) {
              setInputSchema(spec.components.schemas[schemaName])
            }
            const outputSchemaName = getSchemaName(
              pathItem[method].responses["200"]
            )
            const outputSchemaOnResponse = getSchemaFromResponse(
              pathItem[method].responses["200"]
            )
            if (outputSchemaName) {
              setOutputSchema(spec.components.schemas[outputSchemaName])
            } else if (outputSchemaOnResponse) {
              setOutputSchema(outputSchemaOnResponse)
            }
          }
        })
      } catch (error) {
        setError(`Failed to parse JSON: ${error}`)
        console.error("Failed to parse JSON:", error)
      }
    },
    [getSchemaName, getSchemaFromResponse]
  )

  usePythonResults(componentCodeWithSchema, componentCodeCallback)

  return {
    spec,
    method,
    parameters,
    requestBody,
    responses,
    inputSchema,
    outputSchema,
    error,
    functionName,
  }
}
