import React, { useMemo } from "react"
import { Tooltip } from "antd"
import Plot from "react-plotly.js"
import remarkGfm from "remark-gfm"
import { useDispatch } from "react-redux"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import styled, { css } from "styled-components"
import {
  faExpandAlt,
  faCompressAlt,
  faComment,
} from "@fortawesome/pro-solid-svg-icons"
import Ansi from "ansi-to-react"

import { FAMemo } from "@dbai/ui-staples"

import { actions } from "reducers/notebookReducer"
import { useMuteCell } from "components/pages/Workflows/Edit/shared/util"
import JupyterModelView from "components/pages/Workflows/Edit/shared/kernel/widgets/JupyterModelView"

const gutter = 40

const ExecutionResults = styled.div`
  margin-top: -22px;
  margin-left: ${gutter}px;
  display: flex;
  justify-content: stretch;
  box-sizing: border-box;

  .form-group {
    margin-bottom: 0;
  }
`

const MarkdownContainer = styled.div`
  margin-top: -22px;
  margin-left: ${gutter}px;
  justify-content: stretch;
  box-sizing: border-box;

  table {
    border-collapse: collapse;
    border-spacing: 0px;
    display: block;
    max-width: 100%;
    width: 100%;
  }

  th,
  td {
    // border: 1px solid #e0e3e6;
    padding: 6px 13px;
  }

  tr {
    // border-top: 1px solid #e0e3e6;
  }

  tbody tr:nth-child(even) {
    // background-color: #f8fafc;
  }
`

const ResetMarkdown = styled(ReactMarkdown)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: unset;
  }
`

const resultsSpacing = css`
  margin: 0;
  padding: 5px 10px;
`

const PlainContainer = styled.div`
  ${resultsSpacing}
`

const PreContainer = styled.pre`
  ${resultsSpacing}
`

const WarningContainer = styled(PreContainer)`
  && {
    color: #cc6600;
  }
`

const ErrorContainer = styled(PreContainer)`
  color: red;
`

const ResultsContainer = styled.div`
  cursor: text;
  overflow-x: auto;

  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;

  .dataframe {
    min-width: 100%;
    border: none;

    thead th {
      border-top: none;
    }

    td,
    th {
      border-width: 1px 0;
      border-color: #eee;
      padding: 5px;
      text-align: left;
    }
    tr {
      &:last-child {
        td,
        th {
          border-bottom: none;
        }
      }

      &:hover {
        // background-color: #eee;
      }
    }
  }
`

const ErrorOutput = styled(Ansi)`
  display: block;
  border: none;
`

const formatStream = ({ text, isTruncated }, key) => {
  if (!text) return null
  if (isTruncated) {
    return (
      <div key={key}>
        <WarningContainer>LINES TRUNCATED AT 1000 CHARACTERS</WarningContainer>
        <PreContainer>{text}</PreContainer>
      </div>
    )
  }
  return <PreContainer key={key}>{text}</PreContainer>
}

const formatImagePng = ({ data }, key) => {
  if (!data || !data["image/png"]) return null
  return (
    <PlainContainer key={key}>
      <img src={`data:image/jpeg;base64,${data["image/png"]}`} alt="figure" />
    </PlainContainer>
  )
}

const formatPlotly = ({ data }, key) => {
  if (!data || !data["application/vnd.plotly.v1+json"]) return null
  const plotlyProps = structuredClone(data["application/vnd.plotly.v1+json"])
  return (
    <PlainContainer key={key}>
      <Plot {...plotlyProps} />
    </PlainContainer>
  )
}

const formatHtml = ({ data }, key) => {
  if (!data || !data["text/html"]) return null
  return (
    <PlainContainer key={key}>
      <div dangerouslySetInnerHTML={{ __html: data["text/html"] }} />
    </PlainContainer>
  )
}

const formatMarkdown = ({ data }, key) => {
  if (!data || !data["text/markdown"]) return null
  return (
    <PlainContainer key={key}>
      <MarkdownCell source={data["text/markdown"]} />
    </PlainContainer>
  )
}

const formatText = ({ data }, key) => {
  if (!data || !data["text/plain"]) return null
  return <PreContainer key={key}>{data["text/plain"]}</PreContainer>
}

const formatError = (result, key) => {
  if (!result.ename) return null

  return (
    <ErrorContainer key={key}>
      {result.traceback.map((tr, idx) => (
        <ErrorOutput key={`${key}-${idx}`}>{tr}</ErrorOutput>
      ))}
    </ErrorContainer>
  )
}

const widgetMime = "application/vnd.jupyter.widget-view+json"
const formatWidget = ({ data }, key, props) => {
  if (!data || !data[widgetMime]) return null
  const { model_id: id } = data[widgetMime]

  return (
    <PlainContainer key={key}>
      <JupyterModelView id={id} {...props} />
    </PlainContainer>
  )
}

const ResizedIcon = styled(FAMemo)`
  margin-left: ${gutter / 4}px;
  cursor: pointer;
`

const getIconMetadata = showOutput => {
  if (showOutput) {
    return {
      dataTip: "COLLAPSE OUTPUT",
      icon: faCompressAlt,
    }
  }

  return {
    dataTip: "EXPAND OUTPUT",
    icon: faExpandAlt,
  }
}

const MuteIcon = props => {
  const { cellId } = props
  const muteCell = useMuteCell(cellId)

  return (
    <Tooltip title="UNMUTE CELL OUTPUT">
      <ResizedIcon icon={faComment} onClick={muteCell} />
    </Tooltip>
  )
}

const CollapseIcon = props => {
  const dispatch = useDispatch()
  const { showOutput, cellId } = props
  const { icon, dataTip } = getIconMetadata(showOutput)

  const toggleOutput = () => {
    dispatch(
      actions.updateCellOutputVisibility({
        value: !showOutput,
        cellId: cellId,
      })
    )
  }
  return (
    <Tooltip title={dataTip}>
      <ResizedIcon icon={icon} onClick={toggleOutput} />
    </Tooltip>
  )
}

const ResultIcon = props => {
  const { showOutput, muted, cellId } = props
  if (muted) return <MuteIcon cellId={cellId} />
  return <CollapseIcon cellId={cellId} showOutput={showOutput} />
}

const CodeCellResults = props => {
  const {
    cell: { uuid, outputs, metadata },
    hideControls,
  } = props

  const { showOutput, muted } = metadata || {}

  const content = useMemo(() => {
    if (muted) return [<PreContainer key="empty">MUTED</PreContainer>]
    if (!showOutput) {
      if (hideControls) return []
      return [<PreContainer key="empty">...</PreContainer>]
    }
    if (!outputs || outputs.length === 0) return []
    const formatters = [
      formatWidget,
      formatImagePng,
      formatPlotly,
      formatHtml,
      formatMarkdown,
      formatText,
      formatStream,
      formatError,
    ]

    const result = outputs
      .map((result, idx) => {
        const safeResult =
          typeof result === "string" ? JSON.parse(result) : result
        const props = {
          setSelected: () => console.warn("setSelected has been removed"),
        }
        const key = `${uuid}-${idx}`
        return formatters.reduce(
          (pre, fn) => pre || fn(safeResult, key, props),
          null
        )
      })
      .filter(ct => ct !== null)

    return result
  }, [muted, outputs, uuid, showOutput, hideControls])

  if (!content.length) return null

  return (
    <>
      {!hideControls && (
        <ResultIcon muted={muted} cellId={uuid} showOutput={showOutput} />
      )}
      <ExecutionResults>
        <ResultsContainer>{content}</ResultsContainer>
      </ExecutionResults>
    </>
  )
}

const remarkPlugins = [remarkGfm]
const rehypePlugins = [rehypeRaw]

const MarkdownCell = props => {
  const { source = "" } = props

  return (
    <>
      <ResultsContainer>
        <ResetMarkdown
          children={source}
          remarkPlugins={remarkPlugins}
          rehypePlugins={rehypePlugins}
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "")
              return !inline && match ? (
                <SyntaxHighlighter
                  {...props}
                  children={String(children).replace(/\n$/, "")}
                  language={match[1]}
                  PreTag="div"
                />
              ) : (
                <code {...props} className={className}>
                  {children}
                </code>
              )
            },
          }}
        />
      </ResultsContainer>
    </>
  )
}

const MarkdownResults = React.memo(props => {
  const {
    cell: { uuid, metadata, source = [] },
    hideControls,
  } = props

  const plugins = useMemo(() => [remarkGfm], [])

  const { showOutput } = metadata || {}
  if (showOutput === false) {
    if (hideControls) return null
    return (
      <>
        <CollapseIcon cellId={uuid} showOutput={showOutput} />
        <PreContainer key="empty">...</PreContainer>
      </>
    )
  }
  return (
    <>
      {!hideControls && <CollapseIcon cellId={uuid} showOutput={showOutput} />}
      <MarkdownContainer>
        <ResultsContainer>
          <ResetMarkdown children={source.join("")} remarkPlugins={plugins} />
        </ResultsContainer>
      </MarkdownContainer>
    </>
  )
})

const CellResults = props => {
  const {
    cell: { cellType },
    hideControls,
  } = props

  switch (cellType) {
    case "code":
      return <CodeCellResults {...props} hideControls={hideControls} />
    case "markdown":
      return <MarkdownResults {...props} />
    default:
      console.warn("Unable to determine cell type. Assuming code...")
      return <CodeCellResults {...props} />
  }
}

export default React.memo(CellResults)
